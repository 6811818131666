<template>
  <div class="breadcrumb--wrap">
    <VLayout justify-center class="breadcrumbs">
      <RouterLink to="/locations" class="breadcrumb flex shrink"
        >Locations</RouterLink
      >
      <VMenu
        :open-on-hover="!isLocked"
        :open-on-click="false"
        nudge-bottom="27"
        nudge-right="20"
      >
        <template v-slot:activator="{ on }">
          <span
            class="breadcrumb flex shrink"
            @click="$router.push(`/locations/${locationId}`)"
            v-on="on"
            >{{ location.JOB_NUMBER__IDENTIFIER }}</span
          >
        </template>
        <VList subheader dense>
          <VListTile @click="showSwitchLocationForReportModal = true">
            <VIcon class="mr-1">change_circle</VIcon>
            <VListTileContent>
              <VListTileTitle>Switch the Location</VListTileTitle>
            </VListTileContent>
          </VListTile>
        </VList>
      </VMenu>
      <a
        v-if="isAdmin"
        :href="
          `https://builder.knack.com/westmoreland/projectmgmt/records/objects/${dailyReportObjectNumber}/record/${reportId}/edit`
        "
        target="_blank"
        title="Edit in Knack"
        class="breadcrumb flex shrink"
        >{{ report.DAILY_REPORT_NAME }}</a
      >
      <RouterLink
        v-else
        :to="`/locations/${locationId}/dailys/${reportId}`"
        class="breadcrumb flex shrink"
        >{{ report.DAILY_REPORT_NAME }}</RouterLink
      >
    </VLayout>
    <SwitchLocationForReportModal :report="report" />
  </div>
</template>

<script>
import VMenu from '@vuetify/VMenu'
import SwitchLocationForReportModal from '@modals/SwitchLocationForReportModal'
import { mapGetters } from 'vuex'
import { ReportStatuses, ContentTypes } from '@constants/knack'
import { createHelpers } from 'vuex-map-fields'
const { mapFields } = createHelpers({
  getterType: 'getReportField',
  mutationType: 'updateReportField',
})

export default {
  name: 'DailyReportBreadcrumbs',
  components: {
    VMenu,
    SwitchLocationForReportModal,
  },
  props: {
    location: {
      type: Object,
      required: true,
    },
    report: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dailyReportObjectNumber: ContentTypes.DAILY_REPORT,
    }
  },
  computed: {
    ...mapFields(['showSwitchLocationForReportModal']),
    ...mapGetters(['isAdmin']),
    locationId() {
      return this.location.ID
    },
    reportId() {
      return this.report.ID
    },
    isLocked() {
      return this.report.REPORT_STATUS === ReportStatuses.LOCKED
    },
  },
}
</script>

<style lang="scss" scoped>
$font: 18px;
.breadcrumb--wrap {
  padding: 0.5rem 0;
  margin: 0;
  background-color: darken($gray-dark, 10);
}

.breadcrumbs {
  .breadcrumb {
    font-size: $font;
    color: rgba(white, 0.7);
    cursor: pointer;
    &:last-child {
      color: white;
    }
    &:not(:first-child)::before {
      display: inline-block;
      margin: 0 10px 0 8px;
      font-family: 'Material Icons', sans-serif;
      font-size: $font;
      font-style: normal;
      font-weight: 400;
      line-height: 25px;
      color: hsla(0, 0%, 100%, 0.7);
      vertical-align: top;
      content: '\E5CC';
    }

    @media #{$mobile} {
      $font: 14px;

      font-size: $font;
      &::before {
        font-size: $font;
      }
    }
  }
}
</style>
