var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VLayout',{attrs:{"row":"","wrap":""}},[_c('VFlex',{class:[_vm.flexStyles, 'daily-list-item--title cost-code-picklist']},[_c('BasePicklist',_vm._g({attrs:{"disabled":_vm.disabled,"title":_vm.jobCostCodePicklistValue,"value":_vm.jobCostCodePicklistValue,"items":_vm.pickableItems,"hint":"Cost Code","item-text":"PICKLIST_TITLE","rules":[(v) => !!v || 'You must select a Cost Code']},on:{"input":function($event){_vm.$emit('update', {
          JOB_COST_CODE: [
            {
              id: $event,
              identifier: _vm.pickableItems.find((item) => item.ID == $event)
                .JOB_COST_CODE,
            },
          ],
        })}}},_vm.$listeners))],1),_c('VFlex',{class:_vm.flexStyles},[_c('ListItemDisplayOnly',{attrs:{"value":_vm.unitType,"hint":"Unit Type"}})],1),_c('VFlex',{class:_vm.flexStyles},[_c('ListItemText',{attrs:{"disabled":_vm.disabled,"value":_vm.asset.QUANTITY_COMPLETED,"hint":"Quantity Completed"},on:{"input":function($event){return _vm.$emit('update', { QUANTITY_COMPLETED: $event })},"focus":function($event){return _vm.$emit('idleCountdown')}}})],1),_c('VFlex',{class:_vm.flexStyles},[_c('ListItemText',{attrs:{"disabled":_vm.disabled,"value":_vm.asset.COMPLETION_NOTES,"hint":"Completion Notes"},on:{"input":function($event){return _vm.$emit('update', { COMPLETION_NOTES: $event })},"focus":function($event){return _vm.$emit('idleCountdown')}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }