<template>
  <VSnackbar bottom :value="isReportDirty" :timeout="0">
    <VFlex xs10 class="text-xs-left">
      <span v-if="idleSaveCountDownPercentage === 0 || isReportUpdating"
        >Saving...</span
      >
      <span v-else>Saving changes in a moment.</span>
    </VFlex>
    <VFlex xs2>
      <VProgressLinear
        v-if="idleSaveCountDownPercentage > 0"
        :value="idleSaveCountDownPercentage"
      />
    </VFlex>
  </VSnackbar>
</template>

<script>
import VSnackbar from '@vuetify/VSnackbar'
import VProgressLinear from '@vuetify/VProgressLinear'

import { createHelpers } from 'vuex-map-fields'
const { mapFields } = createHelpers({
  getterType: 'getReportField',
  mutationType: 'updateReportField',
})
export default {
  name: 'AutosaveSnackbar',
  components: {
    VSnackbar,
    VProgressLinear,
  },
  data() {
    return {}
  },
  computed: {
    ...mapFields([
      'idleSaveCountDownPercentage',
      'isReportDirty',
      'isReportUpdating',
    ]),
  },
  beforeDestroy() {
    this.idleSaveCountDownPercentage = null
    this.isReportDirty = null
    this.isReportUpdating = null
  },
}
</script>
