var render = function render(){var _vm=this,_c=_vm._self._c;return _c('WithReportAssets',{attrs:{"asset-type":_vm.assetType,"sort-by":'TABLE_ORDER_NUMBER'},scopedSlots:_vm._u([{key:"default",fn:function({ reportAssets, areAssignmentsLoading, reportAssetsError }){return _c('div',{},[_c('DailyReportAssignmentCard',{attrs:{"id":"manpower","title":"Manpower","assets":_vm.sortByLaborerType(reportAssets),"asset-type":_vm.assetType,"loading":areAssignmentsLoading,"error":reportAssetsError,"fields":_vm.fieldsWithClasses,"can-add":false,"can-remove":false,"defaults":{
        assetNameKey: 'LABORER_NAME',
        picklistKey: 'LABORER_NAME',
        assgntKey: 'LABORER_NAME',
      },"is-shift-items-mode-active":_vm.isShiftItemsModeActive,"is-multi-split-on":_vm.isMultiSplitOn,"allows-shift-items":true},on:{"toggleMultiSplit":function($event){_vm.isMultiSplitOn = !_vm.isMultiSplitOn},"toggleShiftItemsMode":_vm.toggleShiftItemsMode,"reloadCard":_vm.reloadCard},scopedSlots:_vm._u([{key:"header",fn:function({ title, assets }){return [_c('VFlex',[_vm._v(_vm._s(title)+" ("+_vm._s(assets.length)+") "),(_vm.assetType)?_c('BaseButton',{staticClass:"no-print",attrs:{"outline":"","small":"","color":"blue","to":{
              name: 'Dispatcher',
              params: { date: _vm.reportDate, assetType: _vm.assetType },
            }}},[_vm._v("View in Dispatcher")]):_vm._e()],1)]}},{key:"default",fn:function({ asset, draft, updateAssetAction, idleCountdown }){return [_c('AssignmentManpower',{attrs:{"asset":asset,"draft":draft,"start-locations":_vm.startLocations,"is-shift-items-mode-active":_vm.isShiftItemsModeActive,"is-multi-split-on":_vm.isMultiSplitOn},on:{"update":updateAssetAction,"idleCountdown":idleCountdown,"picking":function($event){return idleCountdown(-1)},"donePicking":idleCountdown}})]}}],null,true)})],1)}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }