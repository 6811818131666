<template>
  <VFlex
    :class="[
      'display-only',
      'assignment-hours-field',
      { 'print-mode': isPrintModeOn },
      ,
    ]"
  >
    <span
      :class="[
        'assignment-hours-inner px-2',
        { 'error white--text': isErrorVisible },
      ]"
      >{{ value }}
    </span>
    <transition :name="transitionName" mode="out-in">
      <div v-if="isErrorVisible" :key="delta" class="delta-error"
        >{{ delta > 0 ? '+' : '' }}{{ Math.ceil(delta * 100) / 100 }}</div
      >
    </transition>
    <div v-if="hint.length && ['sm', 'xs'].includes($mq)" class="hint">{{
      hint
    }}</div>
  </VFlex>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'ListItemAssignmentHoursDisplay',
  props: {
    value: {
      type: [String, Number],
      default: '-',
    },
    hint: {
      type: String,
      default: '',
    },
    delta: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      transitionName: 'slide-up',
    }
  },
  computed: {
    ...mapGetters(['isPrintModeOn', 'canViewShiftItems']),
    hasError() {
      return this.delta !== 0
    },
    isErrorVisible() {
      return this.hasError && this.canViewShiftItems
    },
  },
  watch: {
    delta(oldDelta, newDelta) {
      this.transitionName = oldDelta > newDelta ? 'slide-down' : 'slide-up'
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@design';
.display-only {
  // styles copied mostly from .v-messages__message
  @media #{$mobile} {
    margin-top: 4px;
  }

  &.print-mode {
    font-size: 0.9em;
  }

  .hint {
    display: block;
    padding-left: 0;
    margin-bottom: 4px;
    font-size: 0.8em;
    color: rgba(0, 0, 0, 0.54);

    @media print {
      display: none !important;
    }
  }
}
.assignment-hours-inner {
  transition: all 0.3s;
  overflow: hidden;
  border-radius: 7px;
}
.delta-error {
  position: absolute;
  bottom: 0px;
  font-size: 0.8em;
  margin-left: 4px;
}

.slide-up-enter-from,
.slide-down-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}
.slide-down-enter-from,
.slide-up-leave-to {
  opacity: 0;
  transform: translateY(10px);
}

.slide-up-leave-from,
.slide-up-enter-to,
.slide-down-leave-from,
.slide-down-enter-to {
  opacity: 1;
}

.slide-up-leave-active,
.slide-up-enter-active,
.slide-down-leave-active,
.slide-down-enter-active {
  transition: all 0.12s;
}
</style>
