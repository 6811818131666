var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VLayout',{staticClass:"equipment-assignment",attrs:{"row":"","wrap":""}},[_c('VFlex',{class:[
      _vm.flexStyles,
      'daily-list-item--title',
      'flex-grow-print flex-3_12-print',
    ]},[_c('ListItemDisplayOnly',{attrs:{"value":_vm.asset.EQUIPMENT_NAME}})],1),_c('VFlex',{class:[_vm.flexStyles, 'flex-grow-print flex-3_12-print']},[_c('BasePicklist',_vm._g({attrs:{"disabled":_vm.disabled,"title":_vm.asset.LABORER_NAME,"value":_vm.asset.LABORER_NAME,"items":_vm.operators,"return-object":"","clearable":"","hint":"Operator / Driver","item-text":"[0].identifier"},on:{"click:clear":function($event){return _vm.$emit('update', { LABORER: null })},"input":function($event){return _vm.$emit('update', { LABORER: $event[0].id })}}},_vm.$listeners))],1),_c('VFlex',{class:[_vm.flexStyles, 'flex-grow-print flex-5_12-print']},[_c('ListItemText',{attrs:{"disabled":_vm.disabled,"value":_vm.asset.EQUIPMENT_NOTES,"hint":"Equip. Notes"},on:{"input":function($event){return _vm.$emit('update', {
          EQUIPMENT_NOTES: $event,
        })},"focus":function($event){return _vm.$emit('idleCountdown')}}})],1),_c('VFlex',{class:[_vm.flexStyles, 'flex-1_12-print']},[_c('ListItemAssignmentHoursDisplay',{attrs:{"value":_vm.totalHours,"hint":"Total Hours","delta":_vm.totalHoursDelta}})],1),_c('VFlex',{class:[_vm.flexStyles, 'flex-1_12-print']},[_c('ListItemCheckbox',{attrs:{"disabled":_vm.disabled,"value":_vm.asset.INCLUDE_IN_TIME__MATERIALS_BILLING,"label":"T&M?"},on:{"change":function($event){return _vm.$emit('update', { INCLUDE_IN_TIME__MATERIALS_BILLING: $event })}}})],1),(_vm.isShiftItemsModeActive && !_vm.isMultiSplitOn)?_c('ShiftItemActions',{key:"split-btn",class:[_vm.flexStyles, 'flex-1_12-print'],attrs:{"shift-items":_vm.shiftItems,"is-list-visible":_vm.isShiftItemsListVisible,"is-working":_vm.asset.isWorking},on:{"add":_vm.addShiftItem,"toggle":function($event){return _vm.toggleShiftItemsListVisibility(_vm.shiftId)}}}):(_vm.isShiftItemsModeActive && _vm.isMultiSplitOn)?_c('VFlex',{key:"multi-split-checkbox",class:_vm.flexStyles},[_c('ListItemCheckbox',{staticClass:"no-print",attrs:{"value":_vm.isMultiSplitSelected,"small":"","color":"blue","hide-details":""},on:{"change":function($event){return _vm.toggleMultiSplitSelection({ assetType: _vm.assetType, shiftId: _vm.shiftId, isSelected: $event })}}})],1):_c('VFlex',{class:[_vm.flexStyles, 'flex-1_12-print']},[_c('ListItemCheckbox',{attrs:{"disabled":_vm.disabled,"value":_vm.asset.STANDBY,"label":"Stanbdy?"},on:{"change":function($event){return _vm.$emit('update', { STANDBY: $event })}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }