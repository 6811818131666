<template>
  <div class="revision-header">
    <span class="author-name bold">{{ revision.author }}</span>
    <template v-if="revision.creationRevision"
      >created this report.
    </template>
    <template v-else-if="isBatchAssetRevision"
      >modified multiple assets on this Report.
    </template>
    <template v-else-if="isAssetSingleUpdate"
      >{{ revision.details.mods.action }}
      <span :class="['key bold', `action-${revision.details.mods.action}`]">{{
        revision.details.mods.assetName
      }}</span>
      in
      <span class="asset-type bold">{{
        HumanReadableAssetTypes[revision.details.mods.assetType.toUpperCase()]
      }}</span>
    </template>
    <template v-else-if="isSingle"
      >changed <span class="key bold">{{ revision.details.key }}</span> from
      "<span class="old-val bold">{{ revision.details.old }}</span
      >" to "<span class="new-val bold">{{ revision.details.new }}</span
      >"
    </template>
    <template v-else
      >{{ revision.creationRevision ? 'created' : 'updated' }} this Report.
    </template>
    <div>
      <span class="revision-date">{{ revision.date }}</span>
    </div>
  </div>
</template>

<script>
import { HumanReadableAssetTypes } from '@constants/knack'
import _get from 'lodash/get'
export default {
  name: 'RevisionHeader',
  components: {},
  props: {
    revision: {
      type: Object,
      required: true,
      default: () => {},
    },
    isSingle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      HumanReadableAssetTypes,
    }
  },
  computed: {
    getRevisionLabel() {
      return `${this.revision.author} ${
        this.revision.creationRevision ? 'created' : 'updated'
      } this Report. ${this.revision.date}`
    }, // getRevisionLabel
    isAssetSingleUpdate() {
      return !!_get(this.revision, 'details.asset') && this.isSingle
    }, // isAssetSingleUpdate
    isBatchAssetRevision() {
      return !!_get(this.revision, 'details.changeGroups')
    }, // isBatchAssetRevision
  },
}
</script>

<style lang="scss" scoped>
.revision-header {
  .revision-date {
    font-size: 0.8rem;
    color: #888;
  }
}
.author-name {
  margin-right: 3px;
  font-weight: 500;
}
</style>
