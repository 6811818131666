<template>
  <VLayout row wrap>
    <VFlex :class="[flexStyles, 'daily-list-item--title cost-code-picklist']">
      <BasePicklist
        :disabled="disabled"
        :title="jobCostCodePicklistValue"
        :value="jobCostCodePicklistValue"
        :items="pickableItems"
        hint="Cost Code"
        item-text="PICKLIST_TITLE"
        :rules="[(v) => !!v || 'You must select a Cost Code']"
        v-on="$listeners"
        @input="
          $emit('update', {
            JOB_COST_CODE: [
              {
                id: $event,
                identifier: pickableItems.find((item) => item.ID == $event)
                  .JOB_COST_CODE,
              },
            ],
          })
        "
      />
    </VFlex>
    <VFlex :class="flexStyles">
      <ListItemDisplayOnly :value="unitType" hint="Unit Type" />
    </VFlex>
    <VFlex :class="flexStyles">
      <ListItemText
        :disabled="disabled"
        :value="asset.QUANTITY_COMPLETED"
        hint="Quantity Completed"
        @input="$emit('update', { QUANTITY_COMPLETED: $event })"
        @focus="$emit('idleCountdown')"
      />
    </VFlex>
    <VFlex :class="flexStyles">
      <ListItemText
        :disabled="disabled"
        :value="asset.COMPLETION_NOTES"
        hint="Completion Notes"
        @input="$emit('update', { COMPLETION_NOTES: $event })"
        @focus="$emit('idleCountdown')"
      />
    </VFlex>
  </VLayout>
</template>

<script>
import { mapGetters } from 'vuex'
import _get from 'lodash/get'
import FlexStylesMixin from '@mixins/flex-styles'

export default {
  name: 'AssignmentRequisitionItem',
  mixins: [FlexStylesMixin(4)],
  props: {
    asset: {
      type: Object,
      required: true,
      default: () => {},
    },
    draft: {
      type: Object,
      required: true,
      default: () => {},
    },
    pickableItems: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['getJobCostCodeById']),
    unitType() {
      let type = _get(this.jobCostCode, 'CONDITIONAL_UNIT_TYPE')
      if (_get(this.draft, 'JOB_COST_CODE')) {
        let newId = _get(this.draft, 'JOB_COST_CODE[0].id')
        let newItem = this.pickableItems.find((m) => m.ID === newId)
        type = _get(newItem, 'CONDITIONAL_UNIT_TYPE')
      }
      return type || ' - '
    }, // unitType
    jobCostCode() {
      let jobCostCodeId = _get(this.asset, 'JOB_COST_CODE[0].id', false)
      if (!jobCostCodeId) {
        return null
      }
      return this.getJobCostCodeById(jobCostCodeId)
    },
    jobCostCodePicklistValue() {
      return _get(this.jobCostCode, 'PICKLIST_TITLE', '')
    },
    disabled() {
      return this.draft.isWorking || false
    }, // disabled
  },
}
</script>

<style lang="scss">
.cost-code-picklist .v-input {
  label,
  input {
    font-size: 13px;
    color: black;
    text-transform: uppercase;
  }
}
</style>
