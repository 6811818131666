<template>
  <VLayout row wrap class="equipment-paywork-item">
    <VFlex :class="[flexStyles, 'daily-list-item--title material-picklist']">
      <BasePicklist
        :disabled="disabled"
        :title="asset.PAYWORK_ITEM_NAME"
        :value="asset.PAYWORK_ITEM_NAME"
        :items="pickableItems"
        hint="Pay/Work Item"
        item-text="PAYWORK_ITEM_NAME"
        :rules="[(v) => !!v || 'You must select a Pay/Work Item']"
        v-on="$listeners"
        @input="
          $emit('update', {
            PAYWORK_ITEM: [
              {
                id: $event,
                identifier: pickableItems.find((item) => item.ID == $event)
                  .PAYWORK_ITEM_NAME,
              },
            ],
          })
        "
      />
    </VFlex>
    <VFlex :class="flexStyles">
      <ListItemDisplayOnly :value="unitType" hint="Unit Type" />
    </VFlex>
    <VFlex :class="flexStyles">
      <ListItemText
        :disabled="disabled"
        :value="asset.QUANTITY_COMPLETED"
        hint="Quantity Completed"
        @input="$emit('update', { QUANTITY_COMPLETED: $event })"
        @focus="$emit('idleCountdown')"
      />
    </VFlex>
    <VFlex :class="flexStyles">
      <ListItemText
        :disabled="disabled"
        :value="asset.COMPLETION_NOTES"
        hint="Completion Notes"
        @input="$emit('update', { COMPLETION_NOTES: $event })"
        @focus="$emit('idleCountdown')"
      />
    </VFlex>
  </VLayout>
</template>

<script>
import _get from 'lodash/get'
import FlexStylesMixin from '@mixins/flex-styles'

export default {
  name: 'AssignmentPayWorkItem',
  mixins: [FlexStylesMixin(4)],
  props: {
    asset: {
      type: Object,
      required: true,
      default: () => {},
    },
    draft: {
      type: Object,
      required: true,
      default: () => {},
    },
    pickableItems: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {}
  },
  computed: {
    unitType() {
      let type = _get(this.asset, 'UNIT_TYPE')
      if (_get(this.draft, 'PAYWORK_ITEM')) {
        let newId = _get(this.draft, 'PAYWORK_ITEM[0].id')
        let newItem = this.pickableItems.find((m) => m.ID === newId)
        type = _get(newItem, 'UNIT_TYPE')
      }
      return type
    }, // unitType

    disabled() {
      return this.draft.isWorking || false
    }, // disabled
  },
}
</script>

<style lang="scss">
.material-picklist .v-input {
  label,
  input {
    font-size: 13px;
    color: black;
    text-transform: uppercase;
  }
}
</style>
