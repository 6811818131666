<template>
  <VCard
    v-observe-visibility="
      (isVisible, entry) => visibilityChanged(isVisible, entry, $attrs.id)
    "
    class="daily-card daily-notes-card mb-3"
  >
    <header class="daily-card--header no-print">{{ title }}</header>
    <div class="list--wrap">
      <div v-if="loading" class="loading--wrap">
        <BaseSpinner
          size="large"
          message="Saving Report"
          text-fg-color="#000000"
        ></BaseSpinner>
      </div>
      <slot>{{ getTheReport.DAILY_REPORT_NAME }}</slot>
    </div>
  </VCard>
</template>

<script>
import { mapGetters } from 'vuex'
import _uniq from 'lodash/uniq'
import { createHelpers } from 'vuex-map-fields'
const { mapFields } = createHelpers({
  getterType: 'getReportField',
  mutationType: 'updateReportField',
})

export default {
  name: 'DailyNotesCard',
  components: {},
  props: {
    title: {
      type: String,
      default: 'Title',
    },
    loading: { type: Boolean, default: false },
    scrollId: { type: String, default: '#scroll-id' },
    schema: { type: Object, default: () => {} },
  },
  data() {
    return {
      errors: [],
      fields: [], // this.$props.schema.fields || [],
    }
  },
  computed: {
    ...mapGetters(['getTheReport']),
    ...mapFields(['visibleQuickLinks']),
  },
  methods: {
    getFieldVal(field) {
      field.text = this.$store.state.report[field.name]
      return field.text
    },
    getRelatedFieldValue(field) {
      var relatedField = this.$store.state.report[field.name]
      if (typeof relatedField === 'undefined' || relatedField.length === 0) {
        return false
      }

      return relatedField[0].identifier || null
    },
    getRelatedFieldId(field) {
      var relatedField = this.$store.state.report[field.name]
      if (typeof relatedField === 'undefined' || relatedField.length === 0) {
        return false
      }
      return relatedField[0].id || null
    },
    datepickerChange(field, value) {
      var change = {
        knackObjId: 19,
        recordId: this.$store.state.report.id,
        valuePairs: [
          {
            name: field.name.replace('_raw', ''),
            val: value,
          },
        ],
      }
      this.$store.dispatch('LOG_KNACK_CHANGE', change)
    },
    visibilityChanged(isVisible, entry, linkSlug) {
      if (isVisible) {
        this.visibleQuickLinks = _uniq([...this.visibleQuickLinks, linkSlug])
      } else {
        this.visibleQuickLinks = [...this.visibleQuickLinks].filter(
          (slug) => slug !== linkSlug
        )
      }
    },
  },
}
</script>

<style lang="scss">
@import '@design';

.list--wrap {
  position: relative;
  .loading--wrap {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    background-color: rgba(white, 0.5);
    transition: background-color 0.3s ease;
  }
}

.daily-notes-item {
  text-align: left;
  textarea {
    padding: 7px 0;
  }
  .daily-notes-item-title {
    font-size: 1.1rem;
    font-weight: bold;
    color: $gray-body;
  }
  .picklist-wrap {
    padding-bottom: 15px;
    .daily-notes-item-title {
      margin-right: 10px;
    }
  }
  .picklist-btn {
    &.required.invalid {
      background-color: $light-red !important;
      border: none;
      // border:1px solid red;
    }
  }

  &.conditional-field-item {
    padding-left: $gutter / 2;
  }

  // Target the first conditional field item after a non-conditional
  // &:not(.conditional-field-item) + .conditional-field-item { }
} // .daily-notes-item
</style>
