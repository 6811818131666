<script>
import { AssetTypes } from '@constants/knack'
import { mapActions, mapGetters } from 'vuex'
import _sortBy from 'lodash/sortBy'
import _isEqual from 'lodash/isEqual'

export default {
  name: 'WithShiftItems',
  props: {
    assetType: {
      type: String,
      default: null,
    },
    shiftIds: {
      type: Array,
      required: true,
      default: () => [],
    },
    sortBy: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      areShiftItemsLoading: false,
      shiftItemsError: false,
      validAssetTypes: [AssetTypes.LABORER, AssetTypes.EQUIPMENT],
    }
  },
  computed: {
    ...mapGetters(['getShiftItemsForShiftIds']),
    shiftItems() {
      if (!this.areShiftItemsLoading) {
        let validAssetTypes = this.validAssetTypes
        let assetType = this.assetType
        if (!validAssetTypes.includes(assetType)) {
          this.$emit('loaded', [])
          return []
        }
        let shiftItems = this.getShiftItemsForShiftIds(
          this.assetType,
          this.shiftIds
        )
        this.$emit('loaded', shiftItems)
        return shiftItems
      }
      return []
    },
    sortedShifts() {
      if (this.sortBy.length) {
        return _sortBy(this.shiftItems, this.sortBy)
      }
      return this.shiftItems
    }, // sortedShifts
  },
  watch: {
    shiftIds: {
      async handler(newShiftIds, oldShiftIds) {
        if (_isEqual(newShiftIds, oldShiftIds)) return
        let validAssetTypes = this.validAssetTypes
        let assetType = this.assetType
        if (!validAssetTypes.includes(assetType)) {
          return
        }

        this.shiftItemsError = false
        this.areShiftItemsLoading = true
        try {
          await this.fetchShiftItemsForShifts({
            assetType: this.assetType,
            shiftIds: newShiftIds,
          })
        } catch (error) {
          this.shiftItemsError = true
        } finally {
          this.areShiftItemsLoading = false
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions(['fetchShiftItemsForShifts']),
  },
  render() {
    return this.$scopedSlots.default({
      shiftItems: this.sortedShifts,
      areShiftItemsLoading: this.areShiftItemsLoading,
      shiftItemsError: this.shiftItemsError,
    })
  },
}
</script>
