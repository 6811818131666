<template>
  <VDialog
    v-model="showSwitchLocationForReportModal"
    :max-width="400"
    class="no-print"
  >
    <WithLocations
      v-if="showSwitchLocationForReportModal"
      :items-per-page="1000"
      :filters="filtersByLocStatus"
      :view-mode="true"
    >
      <div
        slot-scope="{ locations, areLocationsLoading }"
        class="locations-list"
      >
        <VCard class="text-xs-center no-print">
          <VCardTitle class="blue darken-2 white--text">
            <VFlex xs12 mb-2><h2>Select a New Location</h2></VFlex>
          </VCardTitle>

          <div v-if="areLocationsLoading">
            <BaseSpinner
              size="medium"
              message="Loading Locations..."
              text-fg-color="#000"
            />
          </div>

          <VCardText v-else class="font-weight-medium pa-5">
            <div v-if="success">
              <VFlex xs12 mb-2>
                <h3 class="font-weight-medium">Location has been switched</h3>
              </VFlex>
              <VFlex xs12 mb-2>
                <div>Sending you to the new location...</div>
              </VFlex>
            </div>
            <template v-else>
              <VAutocomplete
                v-model="selectedLocation"
                item-text="JOB_NUMBER__IDENTIFIER"
                item-value="ID"
                placeholder="Pick a Location..."
                :items="sortLocsByIdentifier(locations)"
              />
              <div
                >If your desired location is not on the list, contact the system
                administrator to manually switch it.</div
              >
            </template>
          </VCardText>
          <VCardActions text-xs-right>
            <VSpacer />
            <BaseButton
              color="blue darken-2"
              outline
              small
              :disabled="areLocationsLoading || !selectedLocation || success"
              @click="switchLocation"
              >Save</BaseButton
            >
            <BaseButton
              color="orange"
              outline
              small
              :disabled="success"
              @click="showSwitchLocationForReportModal = false"
              >Cancel</BaseButton
            >
          </VCardActions>
        </VCard>
      </div>
    </WithLocations>
  </VDialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import VDialog from '@vuetify/VDialog'
import VAutocomplete from '@vuetify/VAutocomplete'
import { VCardText, VCardActions } from '@vuetify/VCard'
import WithLocations from '@dataProviders/WithLocations'
import { HumanFields } from '@constants/knack'
import _sortBy from 'lodash/sortBy'
import { createHelpers } from 'vuex-map-fields'
const { mapFields } = createHelpers({
  getterType: 'getReportField',
  mutationType: 'updateReportField',
})

export default {
  name: 'SwitchLocationForReportModal',
  components: {
    VDialog,
    VCardText,
    VCardActions,
    VAutocomplete,
    WithLocations,
  },
  props: {
    report: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedLocation: null,
      success: false,
      filtersByLocStatus: {
        match: 'or',
        rules: [
          {
            field: HumanFields.LOCATIONS.STATUS,
            operator: 'is',
            value: 'Ready To Work',
          },
          {
            field: HumanFields.LOCATIONS.STATUS,
            operator: 'is',
            value: 'Work Scheduled',
          },
          {
            field: HumanFields.LOCATIONS.STATUS,
            operator: 'is',
            value: 'Permit Approved But Not Paid Yet',
          },
        ],
      },
    }
  },
  computed: {
    ...mapFields(['showSwitchLocationForReportModal']),
    ...mapGetters(['getLocationFromId', 'getReport']),
  },
  mounted() {
    this.selectedLocation = this.report.LOCATION[0].id
  },
  methods: {
    ...mapActions(['updateReport']),
    async switchLocation() {
      this.$notify({
        title: 'Switching Location...',
      })
      this.success = false
      try {
        let location = await this.getLocationFromId(this.selectedLocation)
        let reportName = `${location.JOB_NUMBER__IDENTIFIER} - ${this.report.REPORT_DATE.date_formatted}`
        let updates = {
          LOCATION: this.selectedLocation,
          LOCATION_IDENTIFIER: location.IDENTIFIER,
          DAILY_REPORT_NAME: reportName,
          DAILY_REPORT_NAME_FORMULA: reportName,
        }

        await this.updateReport(updates)
        this.success = true
        this.$notify({
          title: 'Location Switched Successfully',
          text: 'Switched to ' + location.IDENTIFIER + '.',
          type: 'success',
        })
        setTimeout(() => {
          this.$router.push({
            name: 'Daily Report',
            params: {
              locationId: location.ID,
              reportId: this.$route.params.reportId,
            },
          })
          this.showSwitchLocationForReportModal = false
        }, 1000)
      } catch (error) {
        this.success = false
        this.$notify({
          title: 'Error Switching Location',
          type: 'error',
        })
      }
    }, // switchLocation()
    sortLocsByIdentifier(locations) {
      return _sortBy(locations, 'JOB_NUMBER__IDENTIFIER')
    },
  },
}
</script>

<style>
@media print {
  .v-overlay--active {
    display: none !important;
  }
}
</style>
