<template>
  <VContainer
    v-if="!['sm', 'xs'].includes($mq) || $store.getters.isPrintModeOn"
    class="list--header flex-container hide-on-small-only"
    grid-list-sm
  >
    <VLayout>
      <span
        v-for="field of fields"
        :key="JSON.stringify(field)"
        :class="['col-header flex-item', field.class]"
        >{{ field.title }}</span
      >
    </VLayout>
  </VContainer>
</template>

<script>
export default {
  name: 'DailyReportAssignmentCardFieldLabels',
  props: {
    fields: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {}
  },
}
</script>

<style lang="scss">
.list--header {
  max-width: none;
  padding: 0 (($gutter * 2) - 4) 0 (($gutter / 2) + 2);
  font-weight: bold;
  color: $gray-dark;

  @media #{$mobile} {
    padding-right: 10px;
  }
  &.cannot-remove {
    padding: 0 10px;
  }
}
.col-header {
  padding: 0 2px;
  text-align: left;
}
</style>
