<template>
  <BasePicklist
    v-bind="allBindings"
    :items="times"
    :value="formattedTime"
    :classes="'timepicker'"
    :dark="isNight"
    append-icon="access_time"
    return-object
    item-value="value"
    @change="changeHandler"
    @picking="$emit('picking')"
    @donePicking="$emit('donePicking')"
  />
</template>

<script>
import dayjs from 'dayjs'

export default {
  name: 'Timepicker',
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: 'Date',
    },
  },
  data() {
    return {
      showPicker: false,
      valid: true,
      localValue: null,
    }
  },
  computed: {
    formattedTime() {
      return this.value ? dayjs(this.value).format('h:mm a') : ''
    }, // formattedTime
    isNight() {
      let hour = dayjs(this.localValue).hour()
      return hour >= 17 || hour < 5
    }, // isNight
    allBindings() {
      return { ...this.$props, ...this.$attrs }
    }, // allBindings
    times() {
      let times = []
      for (let i = 0; i < 24; i++) {
        let hr = (12 + i) % 12
        hr = hr === 0 ? 12 : hr
        let minutes = ['00', '30']
        let ampm = i > 11 ? 'pm' : 'am'

        minutes.map((min) => {
          let noonOrMidnight = ''
          if (hr === 12 && min === '00') {
            noonOrMidnight = ampm === 'am' ? ' (Midnight)' : ' (Noon)'
          }
          times.push({
            text: `${hr}:${min} ${ampm}${noonOrMidnight}`,
            value: `${hr}:${min} ${ampm}`,
          })
        })
      }
      return times
    }, // times
  }, // computed
  watch: {
    value(val) {
      this.localValue = val
    },
  },
  mounted() {
    this.localValue = this.value
  },
  methods: {
    changeHandler($event) {
      this.localValue = `${dayjs().format('MM/DD/YYYY')} ${$event.value}`
      this.$emit('change', $event.value)
    }, // changeHandler
  }, // watch
}
</script>

<style lang="scss">
.timepicker {
  .v-icon {
    font-size: 18px;
  }
  .v-select__slot input {
    font-size: 14px;
  }
}
</style>
