<template>
  <VTooltip :disabled="!disabled || isWorking" top>
    <template v-slot:activator="{ on }">
      <span v-on="on">
        <BaseButton
          small
          color="blue"
          class="no-print ma-0"
          :disabled="disabled || isWorking"
          @click="$emit('add')"
          >Split</BaseButton
        >
      </span>
    </template>
    <span>You must save time worked before splitting.</span>
  </VTooltip>
</template>

<script>
import VTooltip from '@vuetify/VTooltip'

export default {
  name: 'ShiftItemSplitButton',
  components: { VTooltip },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    isWorking: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
}
</script>
