import dayjs from '@utils/dayjsCustomParse'
import _isObject from 'lodash/isObject'

function getTimeInMinutes(time) {
  let parseFormat = 'MM/DD/YYYY hh:mm a'

  let originDate = '01/01/2012 12:00 am'
  let start = dayjs(originDate, parseFormat)

  let timestamp
  if (_isObject(time)) {
    timestamp = time.timestamp
  } else {
    time = time.length === 6 ? `0${time}` : time
    timestamp = `01/01/2012 ${time}`
  }
  let end = dayjs(timestamp, parseFormat)

  let minutes = end.diff(start, 'minutes')
  return minutes
}

export default getTimeInMinutes
