<template>
  <VCard>
    <VCardText>
      <VExpansionPanel>
        <VExpansionPanelContent>
          <template v-slot:header>
            <div
              >{{ mutationTitle }} ({{
                objectName === 'Daily Report'
                  ? mutationGroup[0].fields.length
                  : mutationGroup.length
              }})</div
            >
          </template>
          <template v-if="objectName === 'Daily Report'">
            <RevisionMutationV1
              v-for="(mutation, index) of mutationGroup[0].fields"
              :key="index"
              :index="index"
              :mutation-type="mutationType"
              :mutation="mutation"
              :object-name="objectName"
            />
          </template>
          <RevisionMutationV1
            v-for="(mutation, index) of mutationGroup"
            v-else
            :key="index"
            :index="index"
            :mutation-type="mutationType"
            :mutation="mutation"
            :object-name="objectName"
          />
        </VExpansionPanelContent>
      </VExpansionPanel>
    </VCardText>
  </VCard>
</template>

<script>
import {
  VExpansionPanel,
  VExpansionPanelContent,
} from '@vuetify/VExpansionPanel'
import _cap from 'lodash/capitalize'

import { VCardText } from '@vuetify/VCard'
import RevisionMutationV1 from '@dailyReport/Revisions/RevisionMutationV1'

export default {
  name: 'RevisionMutationGroupV1',
  components: {
    VExpansionPanel,
    VExpansionPanelContent,
    VCardText,
    RevisionMutationV1,
  },

  props: {
    objectName: {
      type: String,
      default: '',
    },
    mutationType: {
      type: String,
      default: '',
    },
    mutationGroup: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {}
  },
  computed: {
    mutationTitle() {
      return _cap(this.mutationType)
    },
  },
}
</script>
