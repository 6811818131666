import { render, staticRenderFns } from "./CardManpower.vue?vue&type=template&id=5ab7645e&scoped=true&"
import script from "./CardManpower.vue?vue&type=script&lang=js&"
export * from "./CardManpower.vue?vue&type=script&lang=js&"
import style0 from "./CardManpower.vue?vue&type=style&index=0&id=5ab7645e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ab7645e",
  null
  
)

export default component.exports