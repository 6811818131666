<template>
  <VContainer pa-2>
    <h3 class="py-2">Add One or More Shift Items to Selected Shifts</h3>
    <VForm v-model="isValid">
      <MultiSplitShiftItem
        v-for="shiftItem in shiftItems"
        :key="shiftItem.ID"
        :asset-type="assetType"
        :shift-item="shiftItem"
        :is-working="isSaving"
        @remove="removeMultiSplitShiftItem(shiftItem.ID)"
        @update="updateMultiSplitShiftItem(shiftItem.ID, $event)"
      />
    </VForm>
    <VLayout row>
      <BaseButton
        color="blue"
        outline
        small
        class="mx-auto"
        md-icon="add_circle_outline"
        md-icon-position="right"
        :disabled="isSaving || !isValid"
        :loading="isSaving"
        @click="addMultiSplitShiftItem"
        >Add Shift Item</BaseButton
      >
    </VLayout>
    <BaseButton
      color="blue"
      :disabled="!hasSelectedAtLeastTwoShifts || !isValid || isSaving"
      :loading="isSaving"
      @click="batchCreateShiftItemsAction"
      >Confirm Multi-Split</BaseButton
    >
    <div v-if="!hasSelectedAtLeastTwoShifts" class="error--text font-italic"
      >Select at least two shifts before splitting.</div
    >
  </VContainer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import _get from 'lodash/get'
import nanoid from 'nanoid'

import MultiSplitShiftItem from '@dailyReport/MultiSplitShiftItem'

export default {
  name: 'MultiSplitModule',
  components: {
    MultiSplitShiftItem,
  },
  props: {
    assetType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isValid: false,
      shiftItems: [],
      isSaving: false,
      shiftId: 'multi-split-shift-id',
    }
  },
  computed: {
    ...mapGetters([
      'getMultiSplitSelectionsForAssetType',
      'getLocation',
      'getTheReport',
    ]),
    selectedShifts() {
      return this.getMultiSplitSelectionsForAssetType(this.assetType)
    },
    hasSelectedAtLeastTwoShifts() {
      return this.selectedShifts.length >= 2
    },
  },
  mounted() {
    this.addMultiSplitShiftItem()
  },
  methods: {
    ...mapActions(['batchCreateShiftItems', 'addShiftItemTimestamp']),
    addMultiSplitShiftItem() {
      let dailyReportId = _get(this.getTheReport, 'ID')
      let location = this.getLocation
      let defaultPhase = _get(location, 'DEFAULT_PHASE[0]')

      let shiftItem = {
        ID: nanoid(),
        REGULAR_HOURS: 0,
        OT_HOURS: 0,
        TIME_CREATED: Math.floor(Date.now() + Math.random() * 10),
        PHASE: [defaultPhase],
        DAILY_REPORT: [dailyReportId],
      }
      this.shiftItems.push(shiftItem)
    },

    removeMultiSplitShiftItem(shiftItemId) {
      this.shiftItems = this.shiftItems.filter(
        (shiftItem) => shiftItem.ID !== shiftItemId
      )
    },

    updateMultiSplitShiftItem(shiftItemId, updates) {
      this.shiftItems = this.shiftItems.map((shiftItem) => {
        if (shiftItem.ID === shiftItemId) {
          return { ...shiftItem, ...updates }
        }
        return shiftItem
      })
    },

    async batchCreateShiftItemsAction() {
      this.isSaving = true
      try {
        await this.batchCreateShiftItems({
          shiftItems: this.shiftItems,
          assetType: this.assetType,
          shiftIds: this.selectedShifts,
        })
        this.$emit('close')
        this.shiftItems = []
      } catch (error) {
        console.error(error)
      } finally {
        this.isSaving = false
      }
    },
  }, // methods
}
</script>
