<template>
  <VCard
    ><VCardText>
      <div
        v-if="objectName === 'Daily Report'"
        :class="['revision-mutation-title']"
        >{{ index + 1 }}. {{ Object.keys(mutation)[0] }} :
        {{ mutation[Object.keys(mutation)[0]] }}
      </div>
      <div v-else :class="['revision-mutation-title', { removed: trashed }]"
        >{{ index + 1 }}. {{ keyFieldVal }}
        {{ trashed ? '(Removed)' : '' }}</div
      >
    </VCardText>
  </VCard>
</template>

<script>
import { VCardText } from '@vuetify/VCard'

export default {
  name: 'RevisionMutationV1',
  components: {
    VCardText,
  },

  props: {
    index: {
      type: Number,
      default: 0,
    },
    objectName: {
      type: String,
      default: '',
    },
    mutationType: {
      type: String,
      default: '',
    },
    mutation: {
      type: Object,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {}
  },
  computed: {
    keyFieldVal() {
      let theField
      if (this.objectName === 'Daily Report') {
        return ''
      } else {
        theField = this.mutation.fields.filter((field) => {
          // There should only be one field here
          let fieldName = Object.keys(field)[0]
          return fieldName === 'keyFieldVal'
        })
        if (theField[Object.keys(theField)[0]]) {
          return theField[Object.keys(theField)[0]].keyFieldVal
        } else {
          return 'N/a'
        }
      }
    },
    trashed() {
      let trashed = this.mutation.fields.filter((field) => {
        return !!field.trashed
      })
      return this.mutationType !== 'removed' && trashed.length
    },
  },
}
</script>
