var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VLayout',{staticClass:"material-usage",attrs:{"row":"","wrap":""}},[_c('VFlex',{class:[
      _vm.isPrintModeOn
        ? 'flex-4-print flex-4-sm flex-4-md flex-1-xs flex-item field-wrap'
        : _vm.flexStyles,
      'daily-list-item--title material-picklist',
    ]},[_c('BasePicklist',_vm._g({attrs:{"disabled":_vm.disabled,"title":_vm.asset.MATERIAL_NAME,"value":_vm.asset.MATERIAL_NAME,"items":_vm.materials,"item-text":"MATERIALPART_NAME","hint":"Material/Part","no-data-text":`No Materials found for Job Type '${_vm.getLocation.JOB_TYPE}'`,"rules":[(v) => !!v || 'You must select a Material/Part']},on:{"input":function($event){_vm.$emit('update', {
          MATERIALPART: [
            {
              id: $event,
              identifier: _vm.materials.find((m) => m.ID == $event)
                .MATERIALPART_NAME,
            },
          ],
        })}}},_vm.$listeners))],1),_c('VFlex',{class:_vm.flexStyles},[_c('ListItemDisplayOnly',{attrs:{"value":_vm.unitType,"hint":"Unit Type"}})],1),_c('VFlex',{class:_vm.flexStyles},[_c('ListItemText',{attrs:{"disabled":_vm.disabled,"value":_vm.asset.MATERIAL_NOTES,"hint":"Notes"},on:{"input":function($event){return _vm.$emit('update', { MATERIAL_NOTES: $event })},"focus":function($event){return _vm.$emit('idleCountdown')}}})],1),_c('VFlex',{class:_vm.flexStyles},[_c('ListItemText',{attrs:{"disabled":_vm.disabled,"value":_vm.asset.QUANTITY_SENT_OUT,"hint":"Sent Out","rules":[_vm.isNumber]},on:{"input":function($event){_vm.$emit('update', { QUANTITY_SENT_OUT: Number($event) })},"focus":function($event){return _vm.$emit('idleCountdown')}}})],1),_c('VFlex',{class:_vm.flexStyles},[_c('ListItemText',{attrs:{"disabled":_vm.disabled,"value":_vm.asset.QUANTITY_USED,"hint":"Used on Site","rules":[_vm.isNumber]},on:{"input":function($event){_vm.$emit('update', { QUANTITY_USED: Number($event) })},"focus":function($event){return _vm.$emit('idleCountdown')}}})],1),_c('VFlex',{class:['checkbox-col', _vm.flexStyles]},[_c('ListItemCheckbox',{attrs:{"disabled":_vm.disabled,"value":_vm.asset.INCLUDE_IN_TIME__MATERIALS_BILLING,"hide-details":"","label":"T&M?"},on:{"change":function($event){return _vm.$emit('update', { INCLUDE_IN_TIME__MATERIALS_BILLING: $event })}}})],1),_c('VFlex',{class:['checkbox-col', _vm.flexStyles]},[_c('ListItemCheckbox',{attrs:{"disabled":_vm.disabled,"value":_vm.asset.IS_SPECIAL_MATERIAL,"hide-details":"","label":"Special Material?","print-icon":"star_outline"},on:{"change":function($event){return _vm.$emit('update', { IS_SPECIAL_MATERIAL: $event })}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }