import { render, staticRenderFns } from "./ShiftItem.vue?vue&type=template&id=3ac9d4e9&"
import script from "./ShiftItem.vue?vue&type=script&lang=js&"
export * from "./ShiftItem.vue?vue&type=script&lang=js&"
import style0 from "./ShiftItem.vue?vue&type=style&index=0&id=3ac9d4e9&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports