<template>
  <VTooltip v-model="showConfirmTrash" top>
    <template v-slot:activator="{ on }">
      <BaseButton
        :color="isDisabled ? 'grey lighten-1' : isHovering ? 'red' : 'grey'"
        :disabled="isWorking"
        small
        fab
        class="remove-btn list-item-remove-btn no-print elevation-0"
        md-icon="clear"
        @click="showConfirmTrash = true"
      />
    </template>
    <BaseButton
      v-if="showConfirmTrash"
      small
      color="red"
      class="no-print elevation-0"
      md-icon="delete"
      @click="removeAction"
      >Confirm</BaseButton
    >
  </VTooltip>
</template>

<script>
import VTooltip from '@vuetify/VTooltip'

export default {
  name: 'ListItemRemoveButton',
  components: {
    VTooltip,
  },
  props: {
    isWorking: {
      type: Boolean,
      default: false,
    },
    isHovering: {
      type: Boolean,
      default: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showConfirmTrash: false,
      confirmTrashTimeout: null,
    }
  },
  watch: {
    isDisabled(isDisabled) {
      if (isDisabled) {
        if (this.confirmTrashTimeout) clearTimeout(this.confirmTrashTimeout)
        this.showConfirmTrash = false
      }
    },
    showConfirmTrash(showConfirmTrash) {
      if (showConfirmTrash) {
        if (this.confirmTrashTimeout) clearTimeout(this.confirmTrashTimeout)
        this.confirmTrashTimeout = setTimeout(() => {
          this.showConfirmTrash = false
        }, 3000)
      }
    },
  },
  methods: {
    async removeAction() {
      if (this.isDisabled) return
      if (this.showConfirmTrash) {
        this.$emit('remove')
      }
      this.showConfirmTrash = !this.showConfirmTrash
    }, // removeAction
  },
}
</script>

<style lang="scss">
.list-item-remove-btn.remove-btn {
  top: 50%;
  transform: translateY(-50%);
  .v-icon--right {
    margin: 0;
  }

  @media #{$mobile} {
    top: 20px;
  }
}
</style>
