<template>
  <BaseButton
    small
    color="blue"
    :class="[
      'no-print ma-0 visibility-toggle-btn',
      {
        'defaults-only': onlyHasDefaultItems,
      },
    ]"
    :md-icon="mdIcon"
    md-icon-position="left"
    md-icon-class="mr-2"
    :md-icon-style="isShowing ? 'outlined' : ''"
    :title="isShowing ? 'Hide' : 'Show'"
    @click="$emit('toggle')"
    >{{ buttonText }}</BaseButton
  >
</template>

<script>
export default {
  name: 'ShiftItemVisibilityToggleButton',
  components: {},
  props: {
    numItems: {
      type: Number,
      default: 0,
    },
    numDefaultItems: {
      type: Number,
      default: 0,
    },
    isShowing: {
      type: Boolean,
      default: false,
    },
  }, // props
  computed: {
    onlyHasDefaultItems() {
      return this.numItems === this.numDefaultItems
    },
    buttonText() {
      if (this.onlyHasDefaultItems && !this.isShowing) {
        return 'Default'
      }
      return this.isShowing ? 'Hide' : `Show (${this.numItems})`
    },
    mdIcon() {
      if (!this.onlyHasDefaultItems) {
        return ''
      }
      return `visibility${this.isShowing ? '_off' : ''}`
    },
  }, // computed
}
</script>

<style lang="scss">
.visibility-toggle-btn {
  // width: 90px;
  &.defaults-only {
    width: auto;
  }
}
</style>
