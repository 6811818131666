<template>
  <VCard
    color="grey lighten-4"
    elevation="1"
    :class="[
      'shift-item mb-2 daily-list-item flex-container valign-wrapper',
      { working: isWorking },
      { invalid: !isValid },
    ]"
  >
    <BaseSpinner
      v-if="isWorking"
      class="working-spinner"
      text-fg-color="#444444"
      size="small"
      message="Creating Shift Item..."
      margin="0"
    ></BaseSpinner>
    <VContainer grid-list-sm pa-0 fluid class="shift-item--wrap">
      <VLayout row :wrap="['xs', 'sm'].includes($mq)">
        <VFlex :class="customFlexStyles"></VFlex>
        <VFlex :class="selectFlexStyles">
          <BasePicklist
            :title="selectedPhase"
            :value="selectedJobPhase"
            :items="sortedPhases"
            :disabled="isWorking"
            :rules="[requiredPicklistRule]"
            hide-selected
            item-text="identifierWithDescription"
            item-value="id"
            hint="Phase"
            classes="phase-select"
            return-object
            :height="20"
            @input="updateShiftItemAction({ PHASE: [$event] })"
          />
        </VFlex>
        <VFlex :class="selectFlexStyles">
          <BasePicklist
            :title="selectedCode ?? 'Select a Cost Code...'"
            :value="selectedJobCode"
            :items="sortedCostCodes"
            :disabled="isWorking"
            :rules="[requiredPicklistRule]"
            hide-selected
            item-text="identifierWithDescription"
            item-value="id"
            hint="Code"
            classes="code-select"
            return-object
            @input="updateShiftItemAction({ COST_CODE: [$event] })"
          />
        </VFlex>
        <VFlex :class="customFlexStyles"></VFlex>

        <VFlex :class="customFlexStyles">
          <ListItemNumber
            :value="shiftItem.REGULAR_HOURS"
            hint="Hours"
            :rules="hoursRules"
            :disabled="isWorking"
            :error="!isValidHours && !shiftItem.REGULAR_HOURS"
            @input="updateShiftItemAction({ REGULAR_HOURS: Number($event) })"
          />
        </VFlex>
        <VFlex
          :class="
            `${customFlexStyles} ${isEquipment ? 'equipment-shift--wrap' : ''}`
          "
        >
          <ListItemDisplayOnly
            v-if="isEquipment"
            class="equipment-shift-item"
            value="Reg Hours Only"
          />
          <ListItemNumber
            v-else
            :value="shiftItem.OT_HOURS"
            hint="OT Hours"
            :rules="hoursRules"
            :disabled="isWorking"
            :error="!isValid && !shiftItem.OT_HOURS"
            @input="updateShiftItemAction({ OT_HOURS: Number($event) })"
          />
        </VFlex>
        <VFlex :class="customFlexStyles"></VFlex>
        <VFlex class="remove-btn--wrap">
          <ListItemRemoveButton
            :is-working="isWorking"
            @remove="$emit('remove')"
          />
        </VFlex>
      </VLayout>
    </VContainer>
  </VCard>
</template>

<script>
import { mapGetters } from 'vuex'
import FlexStylesMixin from '@mixins/flex-styles'
import ListItemRemoveButton from '@components/DailyReportView/ListItemRemoveButton'
import { AssetTypes } from '@constants/knack'

import _get from 'lodash/get'
import _sortBy from 'lodash/sortBy'

export default {
  name: 'MultiSplitShiftItem',
  components: {
    ListItemRemoveButton,
  },
  mixins: [FlexStylesMixin(9)],
  props: {
    shiftItem: {
      type: Object,
      required: true,
      default: () => {},
    },
    assetType: {
      type: String,
      required: true,
    },
    isWorking: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hasConfirmedTrash: false,
      isUserEditing: false,
      saveShiftItemTimeout: null,
    }
  },
  computed: {
    ...mapGetters(['getPhasesForTheJob', 'getCostCodesForTheJob']),
    shiftItemId() {
      return this.shiftItem.ID
    },
    customFlexStyles() {
      return this.flexStyles + ' shift-item-flex'
    },
    selectFlexStyles() {
      return 'flex-1-xs flex-item field-wrap shift-item-flex shift-item-select'
    },
    selectedPhase() {
      return _get(this.shiftItem, 'PHASE[0].identifier')
    }, // selectedPhase
    selectedJobPhase() {
      let jobPhase = this.getPhasesForTheJob.find(
        (phase) => phase.identifier === this.selectedPhase
      )
      return _get(jobPhase, 'id')
    },
    selectedCode() {
      return _get(this.shiftItem, 'COST_CODE[0].identifier')
    }, // selectedCode
    selectedJobCode() {
      let jobCode = this.getCostCodesForTheJob.find(
        (code) => code.identifier === this.selectedCode
      )
      return _get(jobCode, 'id')
    },
    sortedPhases() {
      return _sortBy(this.getPhasesForTheJob, 'identifierWithDescription')
    },
    sortedCostCodes() {
      return _sortBy(this.getCostCodesForTheJob, 'identifierWithDescription')
    },
    isValidHours() {
      return this.shiftItem.REGULAR_HOURS + this.shiftItem.OT_HOURS > 0
    },
    isValid() {
      return this.isValidHours && this.selectedCode && this.selectedPhase
    },
    isValidRule() {
      return (v) => {
        return this.isValidHours || `Total hours can't be 0`
      }
    },
    hoursRules() {
      let rules = [(v) => !isNaN(Number(v)) || 'You must enter a number']
      if (!this.isValidHours) {
        rules.push(this.isValidRule)
      }
      return rules
    },
    requiredPicklistRule() {
      return (v) => !!v || 'Please make a selection'
    },
    isEquipment() {
      return this.assetType === AssetTypes.EQUIPMENT
    },
  },
  methods: {
    async updateShiftItemAction(payload) {
      this.$emit('update', payload)
    }, // updateShiftItemAction
  }, // methods
}
</script>

<style lang="scss">
.shift-item {
  &.invalid {
    border-left-color: $red !important;
  }
}
.shift-item-flex {
  .v-text-field {
    .v-input__slot,
    .v-input__control {
      min-height: 30px;

      @media #{$gt_mobile} {
        height: 30px;
      }
    }
  }
  .v-input.v-autocomplete,
  .phase-select,
  .code-select {
    &.v-text-field.v-text-field--enclosed .v-input__slot {
      padding: 0 6px;
    }
    .v-select__slot {
      .v-select__selection,
      .v-label,
      input {
        font-size: 14px;
      }
    }
  }
  &.equipment-shift--wrap {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .equipment-shift-item {
    word-break: break-word;
    font-size: 0.75em;
    font-style: italic;
    color: lighten(#000, 40);
  }
}
</style>
