<template>
  <div class="file-storage-link-button">
    <BaseButton
      class="no-print"
      :disabled="!link"
      :href="url"
      target="_blank"
      small
      md-icon="cloud_upload"
      md-icon-position="left"
      :hint="hint"
      >{{ hint }}</BaseButton
    >
  </div>
</template>

<script>
import _get from 'lodash/get'
export default {
  name: 'FileStorageLinkButton',
  components: {},
  props: {
    location: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    isGoogleDriveLink() {
      return this.link.search('drive.google.com') > -1
    },
    isDropboxLink() {
      return this.link.search('dropbox.com') > -1
    },
    link() {
      let url = _get(this.location, 'GOOGLE_DRIVE.url', '')
      return url || ''
    },
    url() {
      let ua = window.navigator.userAgent
      let iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i)
      if (this.isGoogleDriveLink && iOS) {
        return iOS ? this.link.replace('https://', 'googledrive://') : this.link
      }
      return this.link
    },
    hint() {
      if (this.isGoogleDriveLink) {
        return 'Open Google Drive'
      } else if (this.isDropboxLink) {
        return 'Open Dropbox'
      } else {
        return 'No Google Link'
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
