export var FlexStylesMixin = (numCols) => ({
  computed: {
    flexStyles() {
      let colSizes = ['print', 'sm', 'md']
        .map((size) => `flex-${this.numCols}-${size}`)
        .join(' ')

      return `${colSizes} flex-1-xs flex-item field-wrap`
    }, // flexStyles
  },
  data() {
    return {
      numCols,
    }
  },
})
export default FlexStylesMixin
