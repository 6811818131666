<template>
  <VFlex>
    <BaseButton
      v-if="numShiftItems === 0"
      small
      color="red"
      class="no-print ma-0 add-default-btn"
      @click="$emit('add')"
      >Fix Default</BaseButton
    >
    <ShiftItemSplitButton
      v-else-if="!isListVisible && !hasUserGeneratedItems && !isAdmin"
      :disabled="disabled"
      :is-working="isWorking"
      @add="$emit('add')"
    />
    <template v-else>
      <ShiftItemVisibilityToggleButton
        :num-items="numShiftItems"
        :num-default-items="numDefaultItems"
        :is-showing="isListVisible"
        @toggle="$emit('toggle')"
      />
      <ShiftItemAddButton
        v-if="canAddRemoveShiftItems"
        :disable-btn="isWorking || emptyShiftItemExists"
        @add="$emit('add')"
      />
    </template>
  </VFlex>
</template>

<script>
import ShiftItemSplitButton from '@dailyReport/ShiftItemSplitButton'
import ShiftItemAddButton from '@dailyReport/ShiftItemAddButton'
import ShiftItemVisibilityToggleButton from '@dailyReport/ShiftItemVisibilityToggleButton'
import { mapGetters } from 'vuex'

import _get from 'lodash/get'
export default {
  name: 'ShiftItemActions',
  components: {
    ShiftItemSplitButton,
    ShiftItemAddButton,
    ShiftItemVisibilityToggleButton,
  },
  props: {
    shiftItems: {
      type: Array,
      default: () => [],
    },
    isListVisible: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isWorking: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['canAddRemoveShiftItems', 'isAdmin']),
    numShiftItems() {
      return this.shiftItems.length
    },
    emptyShiftItemExists() {
      return this.shiftItems.some(
        (shiftItem) =>
          !_get(shiftItem, 'PHASE[0].identifier') ||
          !_get(shiftItem, 'COST_CODE[0].identifier')
      )
    },
    hasUserGeneratedItems() {
      return (
        this.shiftItems.filter((shiftItem) => !shiftItem.IS_DEFAULT).length > 0
      )
    },
    numDefaultItems() {
      return this.shiftItems.filter((shiftItem) => shiftItem.IS_DEFAULT).length
    },
  },
}
</script>

<style lang="scss">
.add-shift-item-btn.btn-base.v-btn {
  width: 28px;
  min-width: 0 !important;
  height: 28px;
  .v-icon--right {
    margin: 0;
  }
}
</style>
