<template>
  <BasePrintableField v-if="isPrintModeOn" :value="value" />
  <VTooltip v-else v-model="showTooltipError" top>
    <template v-slot:activator="{ on }">
      <div class="text-input">
        <BaseInput
          ref="input"
          type="number"
          step="0.5"
          min="0"
          :value="value"
          :placeholder="hint.length ? hint : ''"
          :hint="hint"
          solo
          flat
          :hide-details="!isSmOrLess"
          :persistent-hint="isSmOrLess && !!hint.length"
          v-bind="allBindings"
          @mouseover="showErrors = true"
          @mouseout="showErrors = false"
          @errorUpdate="hasErrors = $event"
          v-on="$listeners"
        />
      </div>
    </template>
    <span>{{ errorText }}</span>
  </VTooltip>
</template>

<script>
import VTooltip from '@vuetify/VTooltip'
import { mapGetters } from 'vuex'

export default {
  name: 'ListItemNumber',
  components: { VTooltip },
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    hint: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showErrors: false,
      hasErrors: false,
    }
  },
  computed: {
    ...mapGetters(['isPrintModeOn']),
    allBindings() {
      return { ...this.$props, ...this.$attrs }
    },
    errorBucket() {
      return this.hasErrors
        ? this.$refs.input.$children[0].$data.errorBucket
        : []
    }, // errorBucket
    errorText() {
      return this.hasErrors ? this.errorBucket[0] : ''
    }, // errorText
    isSmOrLess() {
      return ['sm', 'xs'].includes(this.$mq)
    }, // isSmOrLess
    showTooltipError() {
      return this.hasErrors && this.showErrors && !this.isSmOrLess
    }, // showTooltipError
  },
}
</script>
<style lang="scss">
@import '@design';
.text-input {
  @media #{$mobile} {
    width: 100%;
  }

  .error--text .v-input__slot {
    color: #ff5252 !important;
    border: 1px solid #ff5252;
  }

  @media print {
    .v-text-field .v-input__control {
      .v-input__slot {
        padding-right: 0;
        padding-left: 0 !important;
        ::placeholder {
          visibility: hidden;
          opacity: 0;
        }
      }
      .v-text-field__details {
        display: none;
      }
    }
  }
}
</style>
