<template>
  <WithReportAssets :asset-type="assetType" :sort-by="'TABLE_ORDER_NUMBER'">
    <div
      slot-scope="{ reportAssets, areAssignmentsLoading, reportAssetsError }"
    >
      <WithReportAssets
        :asset-type="LaborerType"
        :sort-by="'TABLE_ORDER_NUMBER'"
      >
        <div
          slot-scope="{
            reportAssets: operators,
            areAssignmentsLoading: areOperatorsLoading,
            reportAssetsError: operatorsError,
          }"
        >
          <DailyReportAssignmentCard
            id="equipment"
            title="Equipment"
            :assets="reportAssets"
            :asset-type="assetType"
            :loading="areAssignmentsLoading || areOperatorsLoading"
            :error="reportAssetsError || operatorsError"
            :fields="fieldsWithClasses"
            :can-add="false"
            :can-remove="false"
            :defaults="{
              assetNameKey: 'EQUIPMENT_NAME',
              picklistKey: 'EQUIPMENT_NAME',
              assgntKey: 'EQUIPMENT_NAME',
            }"
            :is-shift-items-mode-active="isShiftItemsModeActive"
            :is-multi-split-on="isMultiSplitOn"
            :allows-shift-items="true"
            @toggleMultiSplit="isMultiSplitOn = !isMultiSplitOn"
            @toggleShiftItemsMode="toggleShiftItemsMode"
            @reloadCard="reloadCard"
          >
            <template v-slot:header="{ title, assets }">
              <VFlex
                >{{ title }} ({{ assets.length }})
                <BaseButton
                  v-if="assetType"
                  outline
                  small
                  color="blue"
                  class="no-print"
                  :to="{
                    name: 'Dispatcher',
                    params: { date: reportDate, assetType },
                  }"
                  >View in Dispatcher</BaseButton
                >
              </VFlex>
            </template>
            <template
              slot-scope="{ asset, draft, updateAssetAction, idleCountdown }"
            >
              <AssignmentEquipment
                :operators="sortByLastName(operators)"
                :asset="asset"
                :draft="draft"
                :is-shift-items-mode-active="isShiftItemsModeActive"
                :is-multi-split-on="isMultiSplitOn"
                @update="updateAssetAction"
                @idleCountdown="idleCountdown"
                @picking="idleCountdown(-1)"
                @donePicking="idleCountdown"
              />
            </template>
          </DailyReportAssignmentCard>
        </div>
      </WithReportAssets>
    </div>
  </WithReportAssets>
</template>

<script>
import dayjs from '@utils/dayjsCustomParse'
import _sortBy from 'lodash/sortBy'
import { AssetTypes, HumanFields, LaborerTypes } from '@constants/knack'
import WithReportAssets from '@dataProviders/WithReportAssets'
import { mapGetters, mapActions } from 'vuex'
import DailyReportAssignmentCard from '@dailyReport/DailyReportAssignmentCard'
import AssignmentEquipment from '@dailyReport/AssignmentEquipment'

export default {
  name: 'CardEquipment',
  components: {
    WithReportAssets,
    DailyReportAssignmentCard,
    AssignmentEquipment,
  },
  data() {
    return {
      assetType: AssetTypes.EQUIPMENT,
      LaborerType: AssetTypes.LABORER,
      fields: [
        { title: 'Equipment', class: 'flex-grow-print flex-3_12-print' },
        {
          title: 'Operator / Driver',
          class: 'flex-grow-print flex-3_12-print',
        },
        { title: 'Equip. Notes', class: 'flex-grow-print flex-4_12-print' },
        'Total Hours',
        { title: 'T&M?', class: 'flex-1_12-print' },
      ],
      isMultiSplitOn: false,
      isShiftItemsModeActive: false,
    }
  },
  computed: {
    ...mapGetters(['getTheReportDate', 'getTheReportForemanId']),
    reportId() {
      return this.$route.params.reportId
    }, // reportId
    reportDate() {
      return dayjs(this.getTheReportDate, 'MM/DD/YYYY').format('MM-DD-YYYY')
    }, // reportDate
    filterByOperator() {
      return [
        {
          field: HumanFields.LABORER.LABORER_TYPE,
          operator: 'is',
          value: LaborerTypes.OPERATOR,
        },
      ]
    }, // filterByOperator
    fieldsWithClasses() {
      let fields = [...this.fields]

      if (!this.isShiftItemsModeActive) {
        fields.push({ title: 'Standby?', class: 'flex-1_12-print' })
      } else {
        let headerName = this.isMultiSplitOn ? 'Split Select' : 'Shift Items'
        fields.push(headerName)
      }

      return fields.map((field) => {
        field = typeof field === 'string' ? { title: field } : field
        return {
          ...field,
          class: field.class ?? 'flex-9-print',
        }
      })
    },
  }, // methods
  watch: {
    isMultiSplitOn(val) {
      if (!val) {
        this.toggleMultiSplitSelectAll({
          assetType: this.assetType,
          isSelected: false,
        })
      }
    }, // isMultiSplitOn
  },
  methods: {
    ...mapActions(['toggleMultiSplitSelectAll']),
    sortByLastName(operators) {
      return _sortBy(operators, (l) => {
        let parts = l.LABORER_NAME.split(' ')
        return parts[parts.length - 1]
      }).map((o) => o.LABORER)
    }, // sortByLastName
    toggleShiftItemsMode() {
      this.isShiftItemsModeActive = !this.isShiftItemsModeActive
      if (!this.isShiftItemsModeActive) {
        this.isMultiSplitOn = false
      }
    }, // toggleShiftItemsMode
    reloadCard() {
      let currentAssetType = this.assetType
      let currentLaborerType = this.LaborerType
      this.assetType = ''
      this.LaborerType = ''
      this.$nextTick(() => {
        this.assetType = currentAssetType
        this.LaborerType = currentLaborerType
      })
    },
  },
}
</script>
