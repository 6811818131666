<template>
  <DailyNotesCard
    id="job-setup"
    class="todays-job-setup text-xs-left"
    title="Today's Job Setup"
    :loading="loading"
    v-on="$listeners"
  >
    <BaseDisplayOnly :value="reportDate" hint="Report Date" />
    <BaseDisplayOnly :value="report.FOREMANS_NAME" hint="Foreman" />
    <BaseInput
      :readonly="loading || locked"
      :value="report.WEATHER"
      label="Weather"
      clearable
      v-on="$listeners"
      @input="updateReport({ WEATHER: $event }, 'Weather')"
      @focus="$emit('idleCountdown')"
    />

    <BaseTextarea
      :readonly="locked || loading"
      :value="report.SUBCONTRACTORSSUPPLIERS"
      label="Sub-Contractors / Suppliers"
      clearable
      v-on="$listeners"
      @input="
        updateReport(
          { SUBCONTRACTORSSUPPLIERS: $event },
          'Sub-Contractors / Suppliers'
        )
      "
      @focus="$emit('idleCountdown')"
    />
    <BaseTextarea
      :readonly="locked || loading"
      :value="report.TODAYS_SCOPE_OF_WORK"
      label="Scope Of Work / Setup Instructions"
      clearable
      v-on="$listeners"
      @input="
        updateReport(
          { TODAYS_SCOPE_OF_WORK: $event },
          'Scope Of Work / Setup Instructions'
        )
      "
      @focus="$emit('idleCountdown')"
    />
    <BaseTextarea
      :readonly="locked || loading"
      :value="report.RENTAL_DUMP_TRUCK_NOTES"
      label="Rental Dump Truck / General Notes"
      clearable
      v-on="$listeners"
      @input="
        updateReport(
          { RENTAL_DUMP_TRUCK_NOTES: $event },
          'Rental Dump Truck / General Notes'
        )
      "
      @focus="$emit('idleCountdown')"
    />
    <BaseTextarea
      class="no-print"
      :outline="false"
      disabled
      readonly
      :value="report.PROJECT_MANAGER_NOTES_FROM_LOCATION"
      label="PM's Notes (Read-only)"
      v-on="$listeners"
    />
  </DailyNotesCard>
</template>

<script>
import dayjs from 'dayjs'
import { mapGetters, mapActions } from 'vuex'

import DailyNotesCard from '@dailyReport/DailyNotesCard'

export default {
  name: 'CardTodaysJobSetup',
  components: {
    DailyNotesCard,
  },
  props: {
    report: {
      type: Object,
      required: true,
      default: () => {},
    },
    loading: { type: Boolean, default: false },
    locked: { type: Boolean, default: false },
  },
  data() {
    return {
      foremanValue: this.report.FOREMANS_NAME || '',
      editForeman: false,
      areForemenLoading: false,
      hold: false,
    }
  },
  computed: {
    ...mapGetters([
      'getForemen',
      'getReportDraft',
      'getLocation',
      'isPrintModeOn',
    ]),
    reportDate: {
      get() {
        // If we've just updated the Report Date it will be a string
        // otherwise it'll be an object feteched from Knack
        let dateString =
          typeof this.getReportDraft.REPORT_DATE === 'string'
            ? this.getReportDraft.REPORT_DATE
            : this.getReportDraft.REPORT_DATE.date
        return dateString.length ? dayjs(dateString).format('MM/DD/YYYY') : ''
      }, // get
      set(val) {
        this.updateReport(
          {
            REPORT_DATE: val,
          },
          'Report Date'
        )
      }, // set
    }, // reportDate
  },
  watch: {
    editForeman: async function(newVal, oldVal) {
      if (newVal && !this.getForemen.length) {
        this.areForemenLoading = true
        await this.fetchForemen()
        this.areForemenLoading = false
      }
    },
  },
  methods: {
    ...mapActions(['fetchForemen', 'modifyReport']),
    updateForeman(foremanId) {
      let foreman = this.getForemen.find((f) => f.ID === foremanId)
      if (foreman) {
        this.updateReport(
          {
            FOREMANS_NAME: foreman.LABORER_NAME,
            FOREMAN: foreman.ID,
          },
          'Foreman'
        )
      }
    }, // updateForeman
    updateReport(updates, label) {
      if (!label) {
        label = Object.keys(updates)[0]
      }
      this.$emit('updateReport', {
        updates,
        label,
      })
    }, // updateReport
  },
}
</script>
