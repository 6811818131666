<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isReportLoading: false,
      reportError: null,
    }
  },
  computed: {
    ...mapGetters(['getReport', 'getReportDraft', 'getReportDiff']),
    report() {
      return this.getReport(this.id)
    },
    draft() {
      return this.getReportDraft
    },
  },
  watch: {
    id: {
      async handler(id) {
        this.isReportLoading = true
        try {
          await this.fetchReport(id)
        } catch (error) {
          this.reportError = true
          throw Promise.reject(error)
        } finally {
          this.isReportLoading = false
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions(['fetchReport']),
  },
  render() {
    const { report, draft, isReportLoading, reportError } = this
    return this.$scopedSlots.default({
      report,
      isReportLoading,
      reportError,
      draft,
      diff: this.getReportDiff,
    })
  },
}
</script>
