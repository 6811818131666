<template>
  <VLayout row wrap class="material-usage">
    <VFlex
      :class="[
        isPrintModeOn
          ? 'flex-4-print flex-4-sm flex-4-md flex-1-xs flex-item field-wrap'
          : flexStyles,
        'daily-list-item--title material-picklist',
      ]"
    >
      <BasePicklist
        :disabled="disabled"
        :title="asset.MATERIAL_NAME"
        :value="asset.MATERIAL_NAME"
        :items="materials"
        item-text="MATERIALPART_NAME"
        hint="Material/Part"
        :no-data-text="
          `No Materials found for Job Type '${getLocation.JOB_TYPE}'`
        "
        :rules="[(v) => !!v || 'You must select a Material/Part']"
        v-on="$listeners"
        @input="
          $emit('update', {
            MATERIALPART: [
              {
                id: $event,
                identifier: materials.find((m) => m.ID == $event)
                  .MATERIALPART_NAME,
              },
            ],
          })
        "
      />
    </VFlex>
    <VFlex :class="flexStyles">
      <ListItemDisplayOnly :value="unitType" hint="Unit Type" />
    </VFlex>
    <VFlex :class="flexStyles">
      <ListItemText
        :disabled="disabled"
        :value="asset.MATERIAL_NOTES"
        hint="Notes"
        @input="$emit('update', { MATERIAL_NOTES: $event })"
        @focus="$emit('idleCountdown')"
      />
    </VFlex>
    <VFlex :class="flexStyles">
      <ListItemText
        :disabled="disabled"
        :value="asset.QUANTITY_SENT_OUT"
        hint="Sent Out"
        :rules="[isNumber]"
        @input="$emit('update', { QUANTITY_SENT_OUT: Number($event) })"
        @focus="$emit('idleCountdown')"
      />
    </VFlex>
    <VFlex :class="flexStyles">
      <ListItemText
        :disabled="disabled"
        :value="asset.QUANTITY_USED"
        hint="Used on Site"
        :rules="[isNumber]"
        @input="$emit('update', { QUANTITY_USED: Number($event) })"
        @focus="$emit('idleCountdown')"
      />
    </VFlex>
    <VFlex :class="['checkbox-col', flexStyles]">
      <ListItemCheckbox
        :disabled="disabled"
        :value="asset.INCLUDE_IN_TIME__MATERIALS_BILLING"
        hide-details
        label="T&amp;M?"
        @change="
          $emit('update', { INCLUDE_IN_TIME__MATERIALS_BILLING: $event })
        "
      />
    </VFlex>
    <VFlex :class="['checkbox-col', flexStyles]">
      <ListItemCheckbox
        :disabled="disabled"
        :value="asset.IS_SPECIAL_MATERIAL"
        hide-details
        label="Special Material?"
        print-icon="star_outline"
        @change="$emit('update', { IS_SPECIAL_MATERIAL: $event })"
      />
    </VFlex>
  </VLayout>
</template>

<script>
import _get from 'lodash/get'
import { mapGetters } from 'vuex'
import FlexStylesMixin from '@mixins/flex-styles'

export default {
  name: 'AssignmentMaterial',
  mixins: [FlexStylesMixin(7)],
  props: {
    asset: {
      type: Object,
      required: true,
      default: () => {},
    },
    materials: {
      type: Array,
      required: true,
      default: () => [],
    },
    draft: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      isNumber: (v) => {
        return !isNaN(Number(v)) || 'You must enter a number'
      },
    }
  },
  computed: {
    ...mapGetters(['getLocation', 'isPrintModeOn']),
    unitType() {
      let type = _get(this.asset, 'UNIT_TYPE')
      if (_get(this.draft, 'MATERIALPART')) {
        let newId = _get(this.draft, 'MATERIALPART[0].id')
        let newMaterial = this.materials.find((m) => m.ID === newId)
        type = _get(newMaterial, 'UNIT_TYPE')
      }
      return type
    }, // unitType

    disabled() {
      return this.draft.isWorking || false
    }, // disabled
  },
}
</script>

<style lang="scss">
.material-picklist .v-input {
  label,
  input {
    font-size: 13px;
    color: black;
    text-transform: uppercase;
  }
}
.daily-list-item.special-material {
  border: 2px solid #2196f3 !important;
  @media print {
    border-color: black !important;
    border-width: 2px 0 !important;
  }
}
</style>
