<template>
  <VCard>
    <VCardText>
      <template v-if="!!changeGroups">
        <VExpansionPanel
          v-for="(changes, objectName) of changeGroups"
          :key="`${revision.timestamp}-${objectName}`"
        >
          <VExpansionPanelContent>
            <template v-slot:header>
              <div>{{ objectName }}</div>
            </template>
            <template v-for="(mutationGroup, mutationType) of changes">
              <RevisionMutationGroupV2
                v-if="!isOriginalRevisionStyle && mutationGroup.length"
                :key="`${revision.timestamp}-${objectName}-${mutationType}`"
                :object-name="objectName"
                :mutation-type="mutationType"
                :mutation-group="mutationGroup"
              />
              <RevisionMutationGroupV1
                v-else-if="mutationGroup.length"
                :key="`${revision.timestamp}-${objectName}-${mutationType}`"
                :object-name="objectName"
                :mutation-type="mutationType"
                :mutation-group="mutationGroup"
              />
            </template>
          </VExpansionPanelContent>
        </VExpansionPanel>
      </template>
      <template v-else-if="updates && isAsset">
        <div
          v-for="updateKey of Object.keys(updates)"
          :key="`${revision.timestamp}-${updateKey}`"
          class="detail-panel"
          ><span class="bold">{{ updateKey }}</span> :
          <span class="bold new-val">{{ updates[updateKey] }}</span>
        </div>
      </template>
      <template v-else-if="updates">
        <div
          v-for="updateKey of Object.keys(updates)"
          :key="`${revision.timestamp}-${updateKey}`"
          class="detail-panel"
          ><span class="bold">{{ updateKey }}</span> :
          <span class="bold new-val">{{
            getValForUpdate(updates[updateKey]['new'])
          }}</span>
          from
          <span class="bold old-val">{{
            getValForUpdate(updates[updateKey]['old'])
          }}</span>
        </div>
      </template>
    </VCardText>
  </VCard>
</template>

<script>
import {
  VExpansionPanel,
  VExpansionPanelContent,
} from '@vuetify/VExpansionPanel'
import _get from 'lodash/get'

import { VCardText } from '@vuetify/VCard'
import RevisionMutationGroupV1 from '@dailyReport/Revisions/RevisionMutationGroupV1'
import RevisionMutationGroupV2 from '@dailyReport/Revisions/RevisionMutationGroupV2'

export default {
  name: 'Revision',
  components: {
    VExpansionPanel,
    VExpansionPanelContent,
    VCardText,
    RevisionMutationGroupV1,
    RevisionMutationGroupV2,
  },

  props: {
    revision: {
      type: Object,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {}
  },
  computed: {
    updates() {
      return (
        _get(this.revision, 'changeGroups') ||
        _get(
          this.revision,
          'details.mods.updates',
          _get(this.revision, 'details.changeGroups')
        )
      )
    }, // updates
    isAsset() {
      return !!_get(this.revision, 'details.asset')
    }, // isAsset
    isOriginalRevisionStyle() {
      return !!_get(this.revision, 'changeGroups')
    }, // isOriginalRevisionStyle
    changeGroups() {
      return (
        _get(this.revision, 'changeGroups') ||
        _get(this.revision, 'details.changeGroups')
      )
    }, // changeGroups
  },
  methods: {
    getValForUpdate(update) {
      return typeof update === 'string' && !update.length ? `""` : update
    }, // getValForUpdate
  },
}
</script>

<style lang="scss" scoped>
.detail-panel {
  padding: 6px 6px;
  font-size: 0.95rem;
  background-color: white;
}
</style>
