<template>
  <VTooltip :disabled="!disableBtn" top>
    <template v-slot:activator="{ on }">
      <span v-on="on">
        <BaseButton
          color="blue"
          class="no-print ma-0 ml-2 add-shift-item-btn"
          md-icon="add"
          fab
          :small="true"
          :disabled="disableBtn"
          @click="$emit('add')"
        />
      </span>
    </template>
    <span>Add a phase and code all entries first.</span>
  </VTooltip>
</template>

<script>
import VTooltip from '@vuetify/VTooltip'

export default {
  name: 'ShiftItemAddButton',
  components: { VTooltip },
  props: {
    disableBtn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
}
</script>
