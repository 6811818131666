<template>
  <VCard>
    <VCardText>
      <VExpansionPanel>
        <VExpansionPanelContent>
          <template v-slot:header>
            <div
              >{{ mutationTitle }} ({{
                objectName === 'Daily Report'
                  ? mutationGroup[0].fields.length
                  : mutationGroup.length
              }})</div
            >
          </template>
          <RevisionMutationV2
            v-for="(assetName, index) of mutationGroup"
            :key="index"
            :index="index"
            :mutation-type="mutationType"
            :asset-name="assetName"
            :object-name="objectName"
          />
        </VExpansionPanelContent>
      </VExpansionPanel>
    </VCardText>
  </VCard>
</template>

<script>
import {
  VExpansionPanel,
  VExpansionPanelContent,
} from '@vuetify/VExpansionPanel'
import _cap from 'lodash/capitalize'

import { VCardText } from '@vuetify/VCard'
import RevisionMutationV2 from '@dailyReport/Revisions/RevisionMutationV2'

export default {
  name: 'RevisionMutationGroupV2',
  components: {
    VExpansionPanel,
    VExpansionPanelContent,
    VCardText,
    RevisionMutationV2,
  },

  props: {
    objectName: {
      type: String,
      default: '',
    },
    mutationType: {
      type: String,
      default: '',
    },
    mutationGroup: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {}
  },
  computed: {
    mutationTitle() {
      return _cap(this.mutationType)
    },
  },
}
</script>
