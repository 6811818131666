<template>
  <VCard
    color="grey lighten-4"
    elevation="1"
    :class="[
      'shift-item mb-2 daily-list-item flex-container valign-wrapper',
      { working: isWorking },
      { draft: shiftItem.draft },
      { modified: hasStagedChanges || isNew },
      { invalid: !isValid },
      { 'default-shift': shiftItem.IS_DEFAULT },
    ]"
    @mouseenter="isHovering = true"
    @mouseleave="isHovering = false"
  >
    <BaseSpinner
      v-if="isWorking"
      class="working-spinner"
      text-fg-color="#444444"
      size="small"
      :message="
        shiftItem.draft ? 'Creating Shift Item...' : 'Saving Shift Item...'
      "
      margin="0"
    ></BaseSpinner>
    <VContainer grid-list-sm pa-0 fluid class="shift-item--wrap">
      <VLayout row :wrap="['xs', 'sm'].includes($mq)">
        <VFlex :class="customFlexStyles"></VFlex>
        <VFlex :class="selectFlexStyles">
          <BasePicklist
            :title="selectedPhase"
            :value="selectedJobPhase"
            :items="sortedPhases"
            :disabled="isWorking || isDefaultShiftItemWorking"
            hide-selected
            item-text="identifierWithDescription"
            item-value="id"
            hint="Phase"
            classes="phase-select"
            return-object
            :height="20"
            @input="updateShiftItemAction({ PHASE: [$event] })"
          />
        </VFlex>
        <VFlex :class="selectFlexStyles">
          <BasePicklist
            :title="selectedCode"
            :value="selectedJobCode"
            :items="sortedCostCodes"
            :disabled="isWorking || isDefaultShiftItemWorking"
            hide-selected
            item-text="identifierWithDescription"
            item-value="id"
            hint="Code"
            classes="code-select"
            return-object
            @input="updateShiftItemAction({ COST_CODE: [$event] })"
          />
        </VFlex>
        <VFlex :class="customFlexStyles"></VFlex>

        <VFlex
          :class="
            `${customFlexStyles} ${isSundayShift ? 'sunday-shift--wrap' : ''}`
          "
        >
          <ListItemDisplayOnly
            v-if="(isSundayShift || isSaturdayShift) && !isEquipment"
            class="sunday-shift"
            :value="isSundayShift ? 'DT Only' : 'OT Only'"
          />
          <ListItemNumber
            v-else
            :value="shiftItem.REGULAR_HOURS"
            hint="Hours"
            :rules="hoursRules"
            :disabled="isWorking || isDefaultShiftItemWorking"
            @input="updateShiftItemAction({ REGULAR_HOURS: Number($event) })"
          />
        </VFlex>
        <VFlex
          :class="
            `${customFlexStyles} ${isEquipment ? 'equipment-shift--wrap' : ''}`
          "
        >
          <ListItemDisplayOnly
            v-if="isEquipment"
            class="sunday-shift"
            :value="'Reg Hours Only'"
          />
          <ListItemNumber
            v-else-if="isSundayShift"
            :value="shiftItem.DT_HOURS"
            hint="DT Hours"
            :rules="hoursRules"
            :disabled="isWorking || isDefaultShiftItemWorking || isEquipment"
            @input="updateShiftItemAction({ DT_HOURS: Number($event) })"
          />
          <ListItemNumber
            v-else
            :value="shiftItem.OT_HOURS"
            hint="OT Hours"
            :rules="hoursRules"
            :disabled="isWorking || isDefaultShiftItemWorking || isEquipment"
            @input="updateShiftItemAction({ OT_HOURS: Number($event) })"
          />
        </VFlex>
        <VFlex :class="customFlexStyles"></VFlex>
        <VFlex v-if="canAddRemoveShiftItems" class="remove-btn--wrap">
          <VTooltip
            :disabled="!isDefaultShiftItem && !isForceRemoveEnabled"
            left
          >
            <template v-slot:activator="{ on }">
              <span v-on="on" @click.shift="forceRemoveAction">
                <ListItemRemoveButton
                  :is-hovering="isHovering"
                  :is-disabled="isDefaultShiftItem && !isForceRemoveEnabled"
                  :is-working="isWorking || isDefaultShiftItemWorking"
                  :can-force="isAdmin"
                  @remove="removeAction"
                />
              </span>
            </template>
            <span
              >Default item can't be deleted<span v-if="isAdmin"
                ><br />(Shift+Click to Force Delete)</span
              ></span
            >
          </VTooltip>
        </VFlex>
      </VLayout>
    </VContainer>
  </VCard>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { createHelpers } from 'vuex-map-fields'
import VTooltip from '@vuetify/VTooltip'
import FlexStylesMixin from '@mixins/flex-styles'
import ListItemRemoveButton from '@components/DailyReportView/ListItemRemoveButton'
import { AssignmentTables, AssetTypes } from '@constants/knack'
import { IDLE_COUNTDOWN_MS } from '@/src/constants/appConfig'

import _get from 'lodash/get'
import _sortBy from 'lodash/sortBy'
import _intersection from 'lodash/intersection'
import _pick from 'lodash/pick'

const { mapFields } = createHelpers({
  getterType: 'getShiftItemsField',
  mutationType: 'updateShiftItemsField',
})

export default {
  name: 'ShiftItem',
  components: {
    ListItemRemoveButton,
    VTooltip,
  },
  mixins: [FlexStylesMixin(9)],
  props: {
    shiftItem: {
      type: Object,
      required: true,
      default: () => {},
    },
    assetType: {
      type: String,
      required: true,
    },
    isSundayShift: {
      type: Boolean,
      required: true,
      default: false,
    },
    isSaturdayShift: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      hasConfirmedTrash: false,
      isUserEditing: false,
      draft: {},
      saveShiftItemTimeout: null,
      isHovering: false,
      isForceRemoveEnabled: false,
      forceRemoveTimeout: null,
    }
  },
  computed: {
    ...mapGetters([
      'getCostCodesForTheJob',
      'getPhasesForTheJob',
      'getDefaultShiftItemForShift',
      'getShiftItemTotalsForShift',
      'getAssgntById',
      'canAddRemoveShiftItems',
      'isAdmin',
    ]),
    ...mapFields(['numUnsavedShiftItems', 'visibleShiftItemLists']),
    shiftItemId() {
      return this.shiftItem.ID
    },
    hasStagedChanges() {
      return Object.keys(this.draft).length
    }, // hasStagedChanges
    customFlexStyles() {
      return this.flexStyles + ' shift-item-flex'
    },
    selectFlexStyles() {
      return 'flex-1-xs flex-item field-wrap shift-item-flex shift-item-select'
    },
    selectedPhase() {
      return _get(this.shiftItem, 'PHASE[0].identifier')
    }, // selectedPhase
    selectedJobPhase() {
      let jobPhase = this.getPhasesForTheJob.find(
        (phase) => phase.identifier === this.selectedPhase
      )
      return _get(jobPhase, 'id')
    },
    selectedCode() {
      return _get(this.shiftItem, 'COST_CODE[0].identifier')
    }, // selectedCode
    selectedJobCode() {
      let jobCode = this.getCostCodesForTheJob.find(
        (code) => code.identifier === this.selectedCode
      )
      return _get(jobCode, 'id')
    },
    sortedPhases() {
      return _sortBy(this.getPhasesForTheJob, 'identifierWithDescription')
    },
    sortedCostCodes() {
      return _sortBy(this.getCostCodesForTheJob, 'identifierWithDescription')
    },
    isWorking() {
      return this.shiftItem.isWorking
    },
    isValid() {
      return !!this.selectedPhase && !!this.selectedCode
    },
    hoursRules() {
      return [(v) => !isNaN(Number(v)) || 'You must enter a number']
    },
    isNew() {
      return !!this.shiftItem.new
    },
    isEquipment() {
      return this.assetType === AssetTypes.EQUIPMENT
    },
    shiftId() {
      let shiftType = AssignmentTables[this.assetType]
      return _get(this.shiftItem, `${shiftType}[0].id`)
    },
    shift() {
      return this.getAssgntById(this.assetType, this.shiftId)
    },
    defaultShiftItem() {
      let defaultShiftItem = this.getDefaultShiftItemForShift(
        this.assetType,
        this.shiftId
      )
      return defaultShiftItem
    },
    defaultShiftItemId() {
      return _get(this.defaultShiftItem, 'ID')
    },
    isDefaultShiftItem() {
      return this.shiftItem.IS_DEFAULT
    },
    isDefaultShiftItemWorking() {
      return this.defaultShiftItem && this.defaultShiftItem.isWorking
    },
    isShowingShiftItemList() {
      return this.visibleShiftItemLists.includes(this.shiftId)
    },
  }, // computed
  watch: {
    async isUserEditing(isUserEditing) {
      if (!isUserEditing) {
        try {
          let shiftItem = {
            ...this.shiftItem,
            ...this.draft,
          }

          let savedShiftItem = await this.saveShiftItem({
            assetType: this.assetType,
            shiftItem,
            updatedProps: Object.keys(this.draft),
          })
          if (savedShiftItem) {
            this.draft = {} // todo set draft
          }
        } catch (error) {
          console.error(error)
        }
      }
    }, // isUserEditing
    hasStagedChanges(newHasChanges, hadChangesBefore) {
      if (!!newHasChanges === !!hadChangesBefore) return
      if (newHasChanges) {
        this.incrementUnsavedAssets()
        this.numUnsavedShiftItems++
      } else {
        this.decrementUnsavedAssets()
        this.numUnsavedShiftItems--
      }
    },
    isNew: {
      async handler(isNew, wasNew) {
        if (isNew) {
          this.incrementUnsavedAssets()
          this.numUnsavedShiftItems++
          await this.idleCountdown(6000)
        } else if (wasNew && !isNew) {
          this.decrementUnsavedAssets()
          this.numUnsavedShiftItems--
        }
      },
      immediate: true,
    },
    async 'shiftItem.forcedUpdates'(forcedUpdates) {
      if (!forcedUpdates) return
      this.draft = { ...this.draft, ...forcedUpdates }
      let countdown = this.isShowingShiftItemList ? 6 : 0
      await this.idleCountdown(countdown)

      this.modifyShiftItem({
        assetType: this.assetType,
        shiftItemId: this.shiftItemId,
        payload: { forcedUpdates: null },
      })
    },
  }, // watch
  methods: {
    ...mapActions([
      'modifyShiftItem',
      'trashShiftItem',
      'removeNewShiftItem',
      'forceUpdateShiftItem',
      'incrementUnsavedAssets',
      'decrementUnsavedAssets',
      'saveShiftItem',
    ]),
    removeAction() {
      if (!this.isDefaultShiftItem) {
        let regularHoursFromAsset = this.defaultShiftItem
          .REGULAR_HOURS_FROM_ASSET
        let regularHours =
          this.defaultShiftItem?.REGULAR_HOURS + this.shiftItem.REGULAR_HOURS
        let otHoursFromAsset = this.defaultShiftItem.OT_HOURS_FROM_ASSET
        let otHours = this.defaultShiftItem?.OT_HOURS + this.shiftItem.OT_HOURS
        let dtHoursFromAsset = this.defaultShiftItem.DT_HOURS_FROM_ASSET
        let dtHours = this.defaultShiftItem?.DT_HOURS + this.shiftItem.DT_HOURS

        if (regularHours > regularHoursFromAsset) {
          regularHours = this.defaultShiftItem?.REGULAR_HOURS_FROM_ASSET
        }
        if (otHours > otHoursFromAsset) {
          otHours = this.defaultShiftItem?.OT_HOURS_FROM_ASSET
        }
        if (dtHours > dtHoursFromAsset) {
          dtHours = this.defaultShiftItem?.DT_HOURS_FROM_ASSET
        }
        this.forceUpdateShiftItem({
          assetType: this.assetType,
          shiftItemId: this.defaultShiftItemId,
          payload: {
            REGULAR_HOURS: regularHours,
            OT_HOURS: otHours,
            DT_HOURS: dtHours,
          },
        })
      }
      let removeFunction = this.shiftItem.draft
        ? this.removeNewShiftItem
        : this.trashShiftItem
      removeFunction({
        assetType: this.assetType,
        shiftItemId: this.shiftItemId,
      })
    }, // removeAction

    forceRemoveAction() {
      if (!this.isAdmin) return
      this.isForceRemoveEnabled = true
      if (this.forceRemoveTimeout) {
        clearTimeout(this.forceRemoveTimeout)
      }
      this.forceRemoveTimeout = setTimeout(() => {
        this.isForceRemoveEnabled = false
      }, 1500)
    }, // forceRemoveAction

    async updateShiftItemAction(payload) {
      let originalDraft = { ...this.draft }
      this.draft = { ...this.draft, ...payload }
      let { assetType, shiftItemId } = this

      // This must happen first before we modify the store with the new values
      let shiftItemTotalsForShift = await this.getShiftItemTotalsForShift(
        this.assetType,
        this.shiftId
      )

      this.modifyShiftItem({ assetType, shiftItemId, payload })

      let updatedTimeKeys = _intersection(Object.keys(payload), [
        'REGULAR_HOURS',
        'OT_HOURS',
        'DT_HOURS',
      ])
      if (!this.isDefaultShiftItem && updatedTimeKeys.length) {
        let originalValues = !_pick(payload, originalDraft).length
          ? _pick(this.shiftItem, updatedTimeKeys)
          : originalDraft

        this.modifyDefaultShiftItem(
          _pick(payload, updatedTimeKeys),
          originalValues,
          shiftItemTotalsForShift
        )
      }

      await this.idleCountdown(6000)
    }, // updateShiftItemAction

    async idleCountdown(timeout = 7000) {
      timeout = IDLE_COUNTDOWN_MS || timeout
      // First things first, clear the existing timeout
      await this.clearIdleCountdown()

      // If its notn new and doesn't have changes
      // or its invalid, short circuit & dont start a timeout
      if ((!this.hasStagedChanges && !this.isNew) || !this.isValid) {
        return false
      }

      // Set UI to show editing state
      this.isUserEditing = true

      // Allow for "pausing" the idle countdown
      // useful for when pickers are open, e.g.
      if (timeout < 0) {
        return false
      }

      // Start countdown
      this.saveShiftItemTimeout = setTimeout(() => {
        this.isUserEditing = false
      }, timeout)
    }, // idleCountdown

    clearIdleCountdown() {
      if (this.saveShiftItemTimeout) {
        clearTimeout(this.saveShiftItemTimeout)
        this.saveShiftItemTimeout = null
      }
    }, // clearIdleCountdown

    async modifyDefaultShiftItem(
      updatedProp,
      originalValues,
      shiftItemTotalsForShift
    ) {
      if (!this.defaultShiftItem) {
        console.warn('No default shift item found for shift.')
        return
      }

      let hoursDeltaPayload = {}

      // after calculating the new hours (whether increased or decreased)
      // we need to compare it against the shift's actual hours to ensure
      // it stays within the shift's bounds

      let updatedKey = Object.keys(updatedProp)[0]
      let updatedValue = updatedProp[updatedKey]

      let originalValue = _get(
        originalValues,
        updatedKey,
        _get(this.shiftItem, updatedKey, 0)
      )
      let delta = updatedValue - originalValue
      let adjustedDefaultItemValue = this.defaultShiftItem[updatedKey] - delta
      if (delta < 0) {
        // if the delta is negative
        //  >> we're adding to the default shift item's hours.
        // so we need to ensure that the default shift item's hours
        // don't exceed the shift's hours
        let shiftHours =
          this.assetType === AssetTypes.EQUIPMENT
            ? 8
            : this.shift[updatedKey] ?? 0
        let totalHours = shiftItemTotalsForShift[updatedKey] ?? 0
        let remainingShiftHours = shiftHours - totalHours

        if (remainingShiftHours < 0) {
          // reverse the adjustment
          adjustedDefaultItemValue = adjustedDefaultItemValue + delta
        }
      }

      hoursDeltaPayload[updatedKey] =
        adjustedDefaultItemValue < 0 ? 0 : adjustedDefaultItemValue

      if (!this.defaultShiftItemId) return

      this.forceUpdateShiftItem({
        assetType: this.assetType,
        shiftItemId: this.defaultShiftItemId,
        payload: hoursDeltaPayload,
      })
    }, // modifyDefaultShiftItem
  }, // methods
}
</script>

<style lang="scss">
.shift-item {
  &.working {
    justify-content: center;
    .shift-item--wrap {
      opacity: 0.2;
    }
  }
  &.draft,
  &.modified {
    border-left-color: $blue !important;
  }
  &.draft {
    $light-blue: desaturate(lighten($blue, 35), 55);
    background-color: $light-blue !important;
    border-right-color: $light-blue !important;
  }
  &.invalid {
    border-left-color: $red !important;
  }
  &.default-shift {
    background-color: #effaf6 !important;
  }
}
.shift-item-flex {
  .v-text-field {
    .v-input__slot,
    .v-input__control {
      min-height: 30px;

      @media #{$gt_mobile} {
        height: 30px;
      }
    }
  }
  .v-input.v-autocomplete,
  .phase-select,
  .code-select {
    &.v-text-field.v-text-field--enclosed .v-input__slot {
      padding: 0 6px;
    }
    .v-select__slot {
      .v-select__selection,
      .v-label,
      input {
        font-size: 14px;
      }
    }
  }
  &.sunday-shift--wrap,
  &.equipment-shift--wrap {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .sunday-shift {
    word-break: break-word;
    font-size: 0.75em;
    font-style: italic;
    color: lighten(#000, 40);
  }
}
</style>
