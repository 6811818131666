<script>
import _get from 'lodash/get'
import { mapActions, mapGetters, createNamespacedHelpers } from 'vuex'
const { mapState: routeState } = createNamespacedHelpers('route')

export default {
  name: 'WithAllAssetsOfType',
  props: {
    itemsPerPage: {
      type: Number,
      default: 1000,
    },
    additionalFilters: {
      type: Array,
      default: () => [],
    },
    filterMatchType: {
      type: String,
      default: 'and',
    },
    assetType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      areAssetsLoading: false,
    }
  },
  computed: {
    ...routeState(['query']),
    ...mapGetters(['getAllAssetsResponses']),
    // full repsonse object including records and metadata too
    response() {
      return !this.areAssetsLoading
        ? this.getAllAssetsResponses(this.assetType)
        : {}
    },
    allAssets() {
      return _get(this.response, 'records', [])
    },
  },

  watch: {
    assetType: {
      async handler(val) {
        this.areAssetsLoading = true
        let query = {
          assetType: this.assetType,
          rows_per_page: this.itemsPerPage,
        }
        if (this.additionalFilters.length) {
          query.filters = {
            match: this.filterMatchType,
            rules: this.additionalFilters,
          }
        }
        await this.fetchAllAssets(query)
        this.areAssetsLoading = false
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions(['fetchAllAssets']),
  },
  render() {
    const { response, areAssetsLoading, allAssets } = this
    return this.$scopedSlots.default({
      areAssetsLoading,
      response,
      allAssets,
    })
  },
}
</script>
