<template>
  <VDialog :value="show" :max-width="400" persistent class="no-print">
    <VCard class="text-xs-center no-print">
      <VCardTitle class="teal accent-2 black--text">
        <VFlex xs12 mb-2
          ><h2>
            Ready to Print!
          </h2></VFlex
        >
      </VCardTitle>
      <VCardText class="font-weight-medium pa-5">
        <p class="px-2">The page has been setup to print.</p>
        <BaseButton color="blue" :loading="isClosing" large @click="printFn"
          >Print the Report</BaseButton
        >
      </VCardText>
      <VCardActions text-xs-right>
        <VSpacer />
        <BaseButton
          color="orange"
          :loading="isClosing"
          outline
          small
          @click="close"
          >Close Window</BaseButton
        >
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
import { mapActions } from 'vuex'
import VDialog from '@vuetify/VDialog'
import { VCardText, VCardActions } from '@vuetify/VCard'

export default {
  name: 'PrintModal',
  components: {
    VDialog,
    VCardText,
    VCardActions,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isClosing: false,
    }
  },
  methods: {
    ...mapActions(['setPrintMode']),
    printFn() {
      if (!window.print) return false
      window.print()
    }, // printFn
    async close() {
      this.isClosing = true
      this.setPrintMode(false)
      this.isClosing = false
    }, // close
  },
}
</script>

<style>
@media print {
  .v-overlay--active {
    display: none !important;
  }
}
</style>
