<template>
  <VCard class="daily-report-revisions text-xs-left no-print">
    <header class="daily-card--header no-print"
      ><VLayout row wrap>
        <VFlex xs12 sm6>
          Revisions
          <span class="pagination-details">
            ({{ startIndex + 1 }} –
            {{ !moreResults ? revisions.length : endIndex }} of
            {{ revisions.length }} )
          </span>
          <BaseButton :disabled="page <= 0" small @click="page--"
            >Newer</BaseButton
          >
          <BaseButton :disabled="!moreResults" small @click="page++"
            >Older</BaseButton
          >
        </VFlex>
        <VFlex xs12 sm6 text-sm-right>
          Items Per Page
          <BaseButton
            small
            fab
            outline
            :disabled="numPerPage === 5"
            color="blue"
            @click="numPerPage = 5"
            >5</BaseButton
          >
          <BaseButton
            small
            fab
            outline
            :disabled="numPerPage === 10"
            color="blue"
            @click="numPerPage = 10"
            >10</BaseButton
          >
          <BaseButton
            small
            fab
            outline
            :disabled="numPerPage === 20"
            color="blue"
            @click="numPerPage = 20"
            >20</BaseButton
          >
        </VFlex>
      </VLayout>
    </header>
    <VContainer fluid>
      <VLayout row>
        <VFlex xs12>
          <VExpansionPanel
            v-for="revision of paginatedRevisions"
            :key="revision.timestamp"
          >
            <VExpansionPanelContent
              :hide-actions="
                isSingleRev(revision) && !hasAssetUpdates(revision)
              "
              :class="[
                { single: isSingleRev(revision) && !hasAssetUpdates(revision) },
              ]"
            >
              <template v-slot:header>
                <RevisionHeader
                  :is-single="isSingleRev(revision)"
                  :revision="revision"
                />
              </template>
              <Revision
                v-if="showRevisionExpansionPanel(revision)"
                :revision="revision"
              />
            </VExpansionPanelContent>
          </VExpansionPanel>
        </VFlex>
      </VLayout>
    </VContainer>
  </VCard>
</template>

<script>
import {
  VExpansionPanel,
  VExpansionPanelContent,
} from '@vuetify/VExpansionPanel'

import _get from 'lodash/get'
import RevisionHeader from '@dailyReport/Revisions/RevisionHeader'
import Revision from '@dailyReport/Revisions/Revision'
import TestRevisions from './TestRevisions.json'
export default {
  name: 'RevisionsList',
  components: {
    VExpansionPanel,
    VExpansionPanelContent,
    RevisionHeader,
    Revision,
  },
  props: {
    revisions: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      TestRevisions,
      page: 0,
      numPerPage: 5,
    }
  },
  computed: {
    paginatedRevisions() {
      return this.revisions.slice(this.startIndex, this.endIndex)
    }, // paginatedRevisions
    moreResults() {
      return this.endIndex < this.revisions.length
    }, // moreResults
    startIndex() {
      return this.page * this.numPerPage
    }, // startIndex
    endIndex() {
      return (this.page + 1) * this.numPerPage
    }, // endIndex
  },
  methods: {
    isSingleRev(rev) {
      return (
        _get(rev, 'details.single', false) ||
        _get(rev, 'creationRevision', false)
      )
    }, // isSingleRev
    hasAssetUpdates(rev) {
      return !!_get(rev, 'details.mods.updates')
    }, // hasAssetUpdates
    showRevisionExpansionPanel(rev) {
      return (
        _get(rev, 'changeGroups') ||
        _get(rev, 'details.mods.updates') ||
        _get(rev, 'details.changeGroups')
      )
    }, // showRevisionExpansionPanel
  },
}
</script>

<style lang="scss">
.pagination-details {
  font-size: 0.8em;
}
.daily-report-revisions {
  font-size: 0.95rem;
  .v-expansion-panel__header {
    background-color: hsl(0, 0, 95%);
    transition: background-color 0.3s ease;
    &:hover {
      background-color: hsl(0, 0, 93%);
    }
  }
  .single .v-expansion-panel__header {
    cursor: auto;
    &:hover {
      background-color: hsl(0, 0, 95%);
    }
  }
  .bold {
    font-weight: 700;
  }

  $new: rgb(211, 255, 196);
  $old: rgb(255, 198, 198);
  $updated: rgb(198, 228, 255);
  .old-val,
  .action-deleted {
    color: darken($old, 60);
  }
  .new-val,
  .action-added {
    color: darken($new, 60);
  }

  .action-updated {
    color: darken($updated, 40);
  }
}
</style>
