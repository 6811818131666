<template>
  <div class="quick-links">
    <span class="quick-links-title pr-2">Jump to :</span>
    <VBtn
      v-for="link in links"
      :key="link.slug"
      round
      depressed
      color="white"
      :dark="!isActive(link.slug)"
      :outline="!isActive(link.slug)"
      :flat="!isActive(link.slug)"
      :class="['quick-link ml-0', { active: isActive(link.slug) }]"
      small
      @click="scrollAndSelect(link.slug)"
      >{{ link.title }}</VBtn
    >
  </div>
</template>

<script>
import VBtn from '@vuetify/VBtn/'
import { createHelpers } from 'vuex-map-fields'
const { mapFields } = createHelpers({
  getterType: 'getReportField',
  mutationType: 'updateReportField',
})
export default {
  name: 'QuickLinks',
  components: { VBtn },
  data() {
    return {
      links: [
        { title: 'Job Setup', slug: 'job-setup' },
        { title: 'Manpower', slug: 'manpower' },
        { title: 'Equipment', slug: 'equipment' },
        { title: 'Material', slug: 'material' },
        { title: 'Pay/Work Items', slug: 'pay-work' },
        { title: "Foreman's Notes", slug: 'foremans-notes' },
      ],
    }
  },
  computed: {
    ...mapFields(['visibleQuickLinks']),
  },
  methods: {
    isActive(slug) {
      return this.visibleQuickLinks.length && this.visibleQuickLinks[0] === slug
    }, // isActive
    scrollAndSelect(slug) {
      this.visibleQuickLinks = [slug]
      this.$scrollTo(`#${slug}`, 700, {
        offset: ['xs', 'sm'].includes(this.$mq) ? -150 : -100,
      })
    }, // scrollAndSelect
  },
}
</script>

<style lang="scss">
.quick-links-title {
  text-align: right;
}
.quick-links {
  align-items: center;
  justify-content: center;

  @media #{$lt_980} {
    width: 100%;
  }

  @media (max-width: 800px) {
    .quick-links-title {
      display: none;
    }
  }
  > * {
    flex: 0 0 auto;
    margin-right: 7px;
    margin-bottom: 5px;
  }
  .quick-link {
    &.active {
      border: 1px solid transparent;
    }

    @media #{$lt_620} {
      height: 26px;
      // margin-right: 1px;
      padding: 0 5px;
      font-size: 0.85rem;
      border-radius: 26px;
    }
  }
}
</style>
