var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VFlex',{class:[
    'display-only',
    'assignment-hours-field',
    { 'print-mode': _vm.isPrintModeOn },
    ,
  ]},[_c('span',{class:[
      'assignment-hours-inner px-2',
      { 'error white--text': _vm.isErrorVisible },
    ]},[_vm._v(_vm._s(_vm.value)+" ")]),_c('transition',{attrs:{"name":_vm.transitionName,"mode":"out-in"}},[(_vm.isErrorVisible)?_c('div',{key:_vm.delta,staticClass:"delta-error"},[_vm._v(_vm._s(_vm.delta > 0 ? '+' : '')+_vm._s(Math.ceil(_vm.delta * 100) / 100))]):_vm._e()]),(_vm.hint.length && ['sm', 'xs'].includes(_vm.$mq))?_c('div',{staticClass:"hint"},[_vm._v(_vm._s(_vm.hint))]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }