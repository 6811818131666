<template>
  <VCard
    v-observe-visibility="
      (isVisible, entry) => visibilityChanged(isVisible, entry, $attrs.id)
    "
    class="daily-card daily-assignment-card mb-3"
  >
    <header class="daily-card--header">
      <VLayout row wrap>
        <slot name="header" :title="title" :assets="assets">
          <VFlex> {{ title }} ({{ assets.length }}) </VFlex>
          <VFlex text-sm-right class="no-print">
            <h5 v-if="!getJobType"
              >No Job Type specified. Defaults not allowed.</h5
            >
            <BaseButton
              v-else-if="hasDefaults && canAdd"
              :disabled="!getJobType || noDefaultsFound"
              :loading="loadingDefaults"
              :outline="noDefaultsFound"
              class="no-print"
              color="blue"
              depressed
              small
              @click="useDefaultItems"
              >{{
                noDefaultsFound
                  ? 'No defaults found for this Job Type'
                  : 'Load Defaults'
              }}</BaseButton
            >
          </VFlex>
        </slot>
        <div
          v-if="allowsShiftItems && canViewShiftItems && assetIds.length"
          class="d-flex align-center"
        >
          <VSpacer />
          <VCheckbox
            v-if="isMultiSplitOn && canAddRemoveShiftItems && !isWeekendShift"
            v-model="areAllSelectedForMultiSplit"
            hide-details
            color="blue"
            title="Toggle Select All"
            class="no-print d-inline-block multi-select-all-checkbox"
            @change="toggleMultiSplitSelection"
          />
          <VTooltip :disabled="!isWeekendShift" bottom>
            <template v-slot:activator="{ on }">
              <span v-on="on">
                <BaseButton
                  v-if="isShiftItemsModeActive && canAddRemoveShiftItems"
                  small
                  :color="isMultiSplitOn ? 'red' : 'blue'"
                  class="no-print multi-toggle-btn"
                  :disabled="isWeekendShift"
                  @click="toggleMultiSplitMode"
                  >{{
                    isMultiSplitOn ? 'Cancel Multi' : 'Split Multiple'
                  }}</BaseButton
                >
              </span>
            </template>
            <span>Disabled for Weekend Shifts</span>
          </VTooltip>

          <BaseButton
            outline
            small
            :color="isShiftItemsModeActive ? 'red' : 'blue'"
            class="no-print split-mode-toggle"
            md-icon="call_split"
            md-icon-position="left"
            @click="$emit('toggleShiftItemsMode')"
            >{{
              isShiftItemsModeActive ? 'Hide Shift Items' : 'Edit Shift Items'
            }}</BaseButton
          >
        </div>
      </VLayout>
    </header>
    <div :class="['list--wrap', { empty: !assets.length }]">
      <WithShiftItems :asset-type="assetType" :shift-ids="assetIds">
        <div slot-scope="{ shiftItems, shiftItemsError, areShiftItemsLoading }">
          <div v-if="areShiftItemsLoading || loading">
            <BaseSpinner size="medium" />
          </div>
          <template v-else-if="error">
            <h3>An error occured while loading {{ title }}</h3>
            <BaseButton color="blue" @click="$emit('reloadCard')"
              >Load Again</BaseButton
            >
          </template>
          <h3 v-else-if="!assetIds.length" class="mb-2"
            >No {{ title }} Records Found</h3
          >
          <div v-else-if="shiftItemsError" class="text-center">
            An error occurred while loading the shift items. Please refresh the
            page.
          </div>
          <template v-else>
            <FieldLabels
              :fields="fields"
              :class="[{ 'cannot-remove': !canRemove }]"
            />
            <AssignmentItem
              v-for="(asset, index) of assets"
              :key="asset.ID"
              :class="[
                { 'first-item': index === 0 },
                { 'cannot-remove': !canRemove },
              ]"
              :asset-id="asset.ID"
              :asset-type="assetType"
              :can-remove="canRemove"
              :assgnt-name-key="defaults.picklistKey || ''"
            >
              <template
                slot-scope="{ updateAssetAction, idleCountdown, draft }"
              >
                <VContainer grid-list-sm pa-0>
                  <VLayout class="assgnt-item--inner">
                    <slot
                      :asset="asset"
                      :draft="draft"
                      :updateAssetAction="updateAssetAction"
                      :idleCountdown="idleCountdown"
                      >{{ JSON.stringify(asset) }}</slot
                    >
                  </VLayout>
                </VContainer>
              </template>
              <template v-show="isShiftItemsModeActive" #shift-items>
                <transition-group name="shift-item-list" mode="out-in">
                  <ShiftItem
                    v-for="shiftItem in shiftItems[asset.ID]"
                    :key="shiftItem.TIME_CREATED"
                    :is-sunday-shift="getIsSundayShift(assetType, asset.ID)"
                    :is-saturday-shift="getIsSaturdayShift(assetType, asset.ID)"
                    :asset-type="assetType"
                    :shift-item="shiftItem"
                  />
                </transition-group>
              </template>
            </AssignmentItem>
          </template>
          <MultiSplitModule
            v-if="isMultiSplitOn"
            :asset-type="assetType"
            @close="$emit('toggleMultiSplit')"
          />
        </div>
      </WithShiftItems>
      <template v-if="!loading && !error">
        <slot
          v-if="canAdd"
          name="add-button"
          :addAction="addAction"
          :isAdding="isAdding"
          :loadingDefaults="loadingDefaults"
        >
          <BaseButton
            md-icon="control_point"
            class="no-print add-record-btn"
            color="blue"
            :loading="isAdding"
            :disabled="loadingDefaults"
            @click="addAction"
            >New {{ title }}</BaseButton
          >
        </slot>
        <BaseButton
          v-if="assets.length && isAdmin && canRemove"
          :md-icon="!confirmTrash ? 'highlight_off' : 'warning'"
          :class="['no-print remove-all-btn']"
          :disabled="allRecordsMarkedForRemoval"
          :color="!confirmTrash ? 'orange' : 'red'"
          @click="removeAllAction"
          >{{ !confirmTrash ? 'Remove All' : 'Are you sure?' }}</BaseButton
        >
      </template>
    </div>
  </VCard>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import {
  HumanFields,
  AssetNameFields,
  AssetObjectNames,
} from '@constants/knack'
import dayNames from '@constants/dayNames'

import WithShiftItems from '@dataProviders/WithShiftItems'
import FieldLabels from '@dailyReport/DailyReportAssignmentCardFieldLabels'
import AssignmentItem from '@dailyReport/AssignmentItem'
import ShiftItem from '@dailyReport/ShiftItem'
import MultiSplitModule from '@dailyReport/MultiSplitModule'
import VTooltip from '@vuetify/VTooltip'
import { VCheckbox } from 'vuetify/lib'

import dayjs from 'dayjs'

import _uniq from 'lodash/uniq'
import _each from 'lodash/each'
import _get from 'lodash/get'
import { createHelpers } from 'vuex-map-fields'
const { mapFields } = createHelpers({
  getterType: 'getReportField',
  mutationType: 'updateReportField',
})

export default {
  name: 'DailyReportAssignmentCard',
  components: {
    WithShiftItems,
    AssignmentItem,
    FieldLabels,
    ShiftItem,
    MultiSplitModule,
    VTooltip,
    VCheckbox,
  },
  props: {
    title: {
      type: String,
      default: 'Title',
    },
    loading: { type: Boolean, default: false },
    error: { type: Boolean, default: false },
    canAdd: { type: Boolean, default: true },
    canRemove: { type: Boolean, default: true },
    scrollId: { type: String, default: '#scroll-id' },
    defaults: {
      type: Object,
      default: () => {
        return { assetNameKey: null, picklistKey: null, assgntKey: null }
      },
    },
    schema: { type: Object, default: () => {} },
    assets: { type: Array, default: () => [] },
    assetType: { type: String, required: true },
    fields: { type: Array, default: () => [] },
    isShiftItemsModeActive: { type: Boolean, default: false },
    isMultiSplitOn: { type: Boolean, default: false },
    allowsShiftItems: { type: Boolean, default: false },
  },
  data() {
    return {
      hasDefaults: true, // this used to come from `schema.defaults`
      loadingDefaults: false,
      isAdding: false,
      noDefaultsFound: false,
      confirmTrash: false,
      showMultiSplitTooltip: false,
      areAllSelectedForMultiSplit: false,
    }
  },
  computed: {
    ...mapGetters([
      'getJobType',
      'getTheReport',
      'isAdmin',
      'canViewShiftItems',
      'canAddRemoveShiftItems',
      'getAssetAssignmentKey',
      'getLocation',
      'getIsSundayShift',
      'getIsSaturdayShift',
    ]),
    ...mapFields(['visibleQuickLinks']),

    allRecordsMarkedForRemoval() {
      return (
        this.assets.filter((a) => !!a.trashed).length === this.assets.length
      )
    }, // allRecordsMarkedForRemoval
    draftsExist() {
      return this.assets.filter((a) => !!a.new || !!a.default).length
    }, // draftsExist
    assetIds() {
      return this.assets.map((a) => a.ID)
    },
    isWeekendShift() {
      let timestamp = _get(this.getTheReport, 'REPORT_DATE.timestamp')
      if (!timestamp) return false
      let dayOfWeek = dayjs(timestamp).format('dddd')
      return [dayNames.SATURDAY, dayNames.SUNDAY].includes(dayOfWeek)
    },
  }, // computed
  methods: {
    ...mapActions([
      'addDraftAsset',
      'trashAsset',
      'removeNewAsset',
      'fetchDefaultAssets',
      'toggleMultiSplitSelectAll',
    ]),
    getRelatedFieldValue(field) {
      var relatedField = this.$store.state.report[field.name]
      if (typeof relatedField === 'undefined' || relatedField.length === 0) {
        return false
      }

      return relatedField[0].identifier || null
    },

    async addAction(asset = {}) {
      this.isAdding = true
      await this.addDraftAsset({
        assetType: this.assetType,
        reportId: this.$route.params.reportId,
        asset,
      })
      this.isAdding = false
    }, // addAction

    async removeAllAction() {
      if (!this.confirmTrash) {
        this.confirmTrash = true
        setTimeout(() => {
          this.confirmTrash = false
        }, 3000)
      } else {
        this.confirmTrash = false
        this.removeAll()
      }
    }, // removeAllAction

    removeAll() {
      this.assets.map((a) => {
        let removeFunction = a.new ? this.removeNewAsset : this.trashAsset
        removeFunction({
          assetType: this.assetType,
          assetId: a.ID,
        })
      })
    }, // removeAll

    async useDefaultItems() {
      // this.addDefaultItems(TestDefaults)

      let report = await this.getTheReport
      this.loadingDefaults = true
      let assignmentKey = this.getAssetAssignmentKey(this.assetType)
      let excludeNames = this.assets.map((a) =>
        _get(a, `[${assignmentKey}][0].identifier`)
      )
      let excludeRules = []
      if (excludeNames.length) {
        excludeRules = excludeNames.map((assetName) => {
          let field =
            HumanFields[AssetObjectNames[this.assetType]][
              AssetNameFields[this.assetType]
            ]
          return {
            field,
            operator: 'is not',
            value: assetName,
          }
        })
      }
      let defaults = await this.fetchDefaultAssets({
        assetType: this.assetType,
        locationJobType: report.JOB_TYPE,
        excludeRules,
      })
      this.loadingDefaults = false
      this.noDefaultsFound = await !defaults.records.length
      this.addDefaultItems(defaults.records)
      return defaults
    }, // useDefaultItems()

    addDefaultItems(items) {
      _each(items, (record, index) => {
        this.addAction({
          [this.defaults.assgntKey]: [
            { id: record.ID, identifier: record[this.defaults.assetNameKey] },
          ],
          default: true,
          [this.defaults.picklistKey]: record[this.defaults.assetNameKey],
        })
      })
    }, // addDefaultItems
    visibilityChanged(isVisible, entry, linkSlug) {
      if (isVisible) {
        this.visibleQuickLinks = _uniq([...this.visibleQuickLinks, linkSlug])
      } else {
        this.visibleQuickLinks = [...this.visibleQuickLinks].filter(
          (slug) => slug !== linkSlug
        )
      }
    },
    toggleMultiSplitMode() {
      this.$emit('toggleMultiSplit')
      if (this.isMultiSplitOn) {
        this.areAllSelectedForMultiSplit = false
      }
    },
    toggleMultiSplitSelection() {
      this.toggleMultiSplitSelectAll({
        assetType: this.assetType,
        isSelected: this.areAllSelectedForMultiSplit,
      })
    },
  }, // methods
}
</script>

<style lang="scss">
@import '@design';
.daily-card--header {
  padding: $gutter / 2 $gutter;
  font-size: 1.4rem;
  font-weight: bold;
  color: black;
  text-align: left;
  background: $gray-light;
}
.list--wrap {
  padding: $gutter;
  transition: all 0.3s ease;
  &.empty {
    padding: $gutter * 3 $gutter;
  }
}

.list-item-field {
  width: 100%;
  padding: 3px 5px 3px 0;
  margin-right: 7px;
  text-align: left;
  border: 1px solid transparent;
  transition: border-color 0.3s ease;
  &.btn-with-icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.9rem;

    @media #{$mobile} {
      display: block;
      font-size: 1.1rem;
    }
  }
  &.required,
  &[class*='required-type'] {
    &.invalid {
      border: 1px solid red;
    }
  }
}

.item-details {
  width: 100%;
}

.list-item-field--wrap {
  overflow: hidden;
  // height:100%;
  &.bold {
    font-weight: bold;
  }
  &.editable {
    padding: 0;
    .list-item-field:not(.type-checkbox) {
      padding-left: 5px;
      cursor: pointer;
      background-color: white;
      transition: background-color 0.3s ease;
      &:hover {
        background-color: rgba(white, 0.3);
      }
      // border-bottom:1px solid transparent;
    }
  }
  .picklist-btn {
    &.required.invalid {
      background-color: $light-red;
      border: none;
      // border:1px solid red;
    }
  }
}

.errors-list {
  li {
    margin-bottom: 10px;
  }
}

$dim: 20px;

.checkbox--wrap {
  label {
    height: $dim;
  }
}

.remove-btn,
.save-assgnt-btn {
  &,
  &:focus,
  &:hover {
    position: absolute;
  }

  right: 10px;
  width: $dim;
  height: $dim;
  line-height: $dim;

  @media #{$mobile} {
    top: 10px;
  }

  .material-icons {
    font-size: 1rem;
    line-height: $dim;
  }
}
.multi-select-all-checkbox {
  padding-top: 0;
  .v-input--selection-controls__input {
    margin-right: 0;
  }
}
</style>

<style scoped>
.container.grid-list-sm .assgnt-item--inner {
  margin: -2px;
}

@media print {
  .daily-assignment-card >>> .list--wrap > .daily-list-item.first-item {
    margin-top: 6px !important;
    border-top-color: black !important;
  }
}
.shift-item-list-enter-active,
.shift-item-list-leave-active {
  transition: all 0.2s;
}
.shift-item-list-enter, .shift-item-list-leave-to /* .shift-item-list-leave-active below version 2.1.8 */ {
  opacity: 0.1;
  transform: translateX(-20%);
}
</style>
