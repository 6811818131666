<template>
  <VCard
    ><VCardText>
      <div class="revision-mutation-title"
        >{{ index + 1 }}. {{ assetName }}</div
      >
    </VCardText>
  </VCard>
</template>

<script>
import { VCardText } from '@vuetify/VCard'

export default {
  name: 'RevisionMutationV2',
  components: {
    VCardText,
  },

  props: {
    index: {
      type: Number,
      default: 0,
    },
    objectName: {
      type: String,
      default: '',
    },
    mutationType: {
      type: String,
      default: '',
    },
    assetName: {
      type: String,
      required: true,
      default: 'Asset',
    },
  },
  data() {
    return {}
  },
}
</script>
