var render = function render(){var _vm=this,_c=_vm._self._c;return _c('DailyNotesCard',_vm._g({staticClass:"todays-job-setup text-xs-left",attrs:{"id":"job-setup","title":"Today's Job Setup","loading":_vm.loading}},_vm.$listeners),[_c('BaseDisplayOnly',{attrs:{"value":_vm.reportDate,"hint":"Report Date"}}),_c('BaseDisplayOnly',{attrs:{"value":_vm.report.FOREMANS_NAME,"hint":"Foreman"}}),_c('BaseInput',_vm._g({attrs:{"readonly":_vm.loading || _vm.locked,"value":_vm.report.WEATHER,"label":"Weather","clearable":""},on:{"input":function($event){return _vm.updateReport({ WEATHER: $event }, 'Weather')},"focus":function($event){return _vm.$emit('idleCountdown')}}},_vm.$listeners)),_c('BaseTextarea',_vm._g({attrs:{"readonly":_vm.locked || _vm.loading,"value":_vm.report.SUBCONTRACTORSSUPPLIERS,"label":"Sub-Contractors / Suppliers","clearable":""},on:{"input":function($event){return _vm.updateReport(
        { SUBCONTRACTORSSUPPLIERS: $event },
        'Sub-Contractors / Suppliers'
      )},"focus":function($event){return _vm.$emit('idleCountdown')}}},_vm.$listeners)),_c('BaseTextarea',_vm._g({attrs:{"readonly":_vm.locked || _vm.loading,"value":_vm.report.TODAYS_SCOPE_OF_WORK,"label":"Scope Of Work / Setup Instructions","clearable":""},on:{"input":function($event){return _vm.updateReport(
        { TODAYS_SCOPE_OF_WORK: $event },
        'Scope Of Work / Setup Instructions'
      )},"focus":function($event){return _vm.$emit('idleCountdown')}}},_vm.$listeners)),_c('BaseTextarea',_vm._g({attrs:{"readonly":_vm.locked || _vm.loading,"value":_vm.report.RENTAL_DUMP_TRUCK_NOTES,"label":"Rental Dump Truck / General Notes","clearable":""},on:{"input":function($event){return _vm.updateReport(
        { RENTAL_DUMP_TRUCK_NOTES: $event },
        'Rental Dump Truck / General Notes'
      )},"focus":function($event){return _vm.$emit('idleCountdown')}}},_vm.$listeners)),_c('BaseTextarea',_vm._g({staticClass:"no-print",attrs:{"outline":false,"disabled":"","readonly":"","value":_vm.report.PROJECT_MANAGER_NOTES_FROM_LOCATION,"label":"PM's Notes (Read-only)"}},_vm.$listeners))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }