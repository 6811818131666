<template>
  <VFlex :class="['display-only', { 'print-mode': isPrintModeOn }]">
    <span>{{ value }}</span
    ><span v-if="hint.length && ['sm', 'xs'].includes($mq)" class="hint">{{
      hint
    }}</span>
  </VFlex>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'ListItemDisplayOnly',
  props: {
    value: {
      type: [String, Number],
      default: '-',
    },
    hint: {
      type: String,
      default: '',
    },
  },
  data() {
    return {}
  },
  computed: mapGetters(['isPrintModeOn']),
}
</script>
<style lang="scss" scoped>
@import '@design';
.display-only {
  // styles copied mostly from .v-messages__message
  @media #{$mobile} {
    margin-top: 4px;
  }

  &.print-mode {
    font-size: 0.9em;
  }

  .hint {
    display: block;
    padding-left: 0;
    margin-bottom: 4px;
    font-size: 0.8em;
    color: rgba(0, 0, 0, 0.54);

    @media print {
      display: none !important;
    }
  }
}
</style>
